import React from 'react';
import styled from 'styled-components';

const ProjectDetailContainer = styled.div`
  padding: 3rem 2rem;
  background-color: #f9fafb;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const Date = styled.p`
  font-size: 0.9rem;
  color: #888;
`;

const TagsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: #0077b5;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: auto;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  border-bottom: 2px solid #0077b5;
  padding-bottom: 0.5rem;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #555;
`;

const ListItem = styled.li`
  margin-bottom: 0.8rem;
  line-height: 1.6;
`;

const Button = styled.button`
  display: flex;
  margin: 0 auto;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0077b5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #005f8d;
  }
`;

// New UI section styles
const UIImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const HalfImage = styled.img`
  width: 48%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const CarpoolingApp = () => {
  const tags = ['React', 'Firebase', 'App Development'];
  const datePublished = '2023-05-10';

  return (
    <ProjectDetailContainer>
      <Header>
        <Title>Carpooling App</Title>
        <Date>Published on: {datePublished}</Date>
      </Header>
      <Image src="/images/carpool.jpg" alt="Carpooling App" />
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>

      <br />
      <br />
      <br />

      <Section>
        <SectionTitle>Overview</SectionTitle>
        <Text>
          After over 3 years at ACG, the most common concern among friends and classmates was the shortage of parking spots around campus. The university’s strict attendance policy exacerbates this issue, often forcing students to choose between parking illegally or risking an absence for being late. Recognizing this opportunity, I decided to revisit my computer science background and develop a carpool app for fellow students. The app matches students based on their addresses, schedules, and interests, enabling them to carpool to class. Beyond the sustainability benefits, the project aims to foster social connections among students. The app is currently in development, and I am engaging with the university’s administration to bring them on board as a key partner.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Objectives</SectionTitle>
        <Text>
          The primary objectives of this project are:
        </Text>
        <List>
          <ListItem>Address the parking issue on campus by promoting carpooling among students.</ListItem>
          <ListItem>Provide a platform that matches students based on their location, schedules, and interests.</ListItem>
          <ListItem>Foster social connections and build a stronger community within the university.</ListItem>
          <ListItem>Promote sustainability through reduced vehicle usage and emissions.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Tools Used</SectionTitle>
        <Text>
          The following tools and technologies were used to develop the carpooling app:
        </Text>
        <List>
          <ListItem>React for front-end development</ListItem>
          <ListItem>Firebase for authentication and real-time database management</ListItem>
          <ListItem>Google Maps API for location services and route optimization</ListItem>
          <ListItem>Styled-components for styling the user interface</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Development Process</SectionTitle>
        <Text>
          The development of the app follows an agile approach, involving the following key stages:
        </Text>
        <List>
          <ListItem>Requirement gathering from students and university administration.</ListItem>
          <ListItem>Designing the user interface to ensure an intuitive and user-friendly experience.</ListItem>
          <ListItem>Setting up Firebase for user authentication and schedule management.</ListItem>
          <ListItem>Integrating the Google Maps API to facilitate carpool matches based on location and routes.</ListItem>
          <ListItem>Testing the app with a small group of students for feedback and iterative improvements.</ListItem>
        </List>
      </Section>

      {/* New UI Section */}
      <Section>
        <SectionTitle>UI Design</SectionTitle>
        <Image src="/images/carpool_login.png" alt="Carpool App Login UI" />
        <Image src="/images/carpool_home.png" alt="Carpool App Home UI" />
        <UIImagesContainer>
          <HalfImage src="/images/carpool_user_data.png" alt="Carpool User Data UI" />
          <HalfImage src="/images/carpool_user_schedule.png" alt="Carpool User Schedule UI" />
        </UIImagesContainer>
        <Image src="/images/carpool_find.png" alt="Carpool App Find a Ride UI" />
        <Image src="/images/carpool_notifications.png" alt="Carpool App Notifications UI" />
      </Section>

      <Section>
        <SectionTitle>Current Status</SectionTitle>
        <Text>
          The project has been temporarily halted as I have been informed that a team at ACG has already been developing a similar carpool app for university students. Although no official announcement or release has been made, I am exploring alternative directions for this project while keeping it in its current state.
        </Text>
      </Section>

      <Button onClick={() => window.history.back()}>Back to Projects</Button>
    </ProjectDetailContainer>
  );
};

// Export metadata for use in the Projects page
export const carpoolingAppMetadata = {
  title: 'Carpooling App',
  description: 'Developed a React and Firebase app for university students to organize carpooling.',
  tags: ['React', 'Firebase', 'App Development'],
  languages: ['JavaScript', 'React'],
  datePublished: '2024-10-20',
  path: '/projects/carpooling-app',
  coverImage: 'images/carpool.jpg',
  status: 'Halted'
};

export default CarpoolingApp;