import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333333; /* Dark gray to match the cover title */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #555555; /* Lighter gray for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555; /* Dark gray for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #f9f9f9; /* Light gray background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #f15a22; /* Soft orange color to match the subtitle */
  border-bottom: 2px solid #f15a22;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark text for readability */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f3f3f3; /* Light background for quotes */
  border-left: 4px solid #f15a22;
  border-radius: 4px;
  color: #333333; /* Dark gray for quote text */
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #f15a22; /* Soft orange for tags */
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #f15a22;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #000;
  }
`;

export const OutliersMetadata = {
  title: "Outliers",
  description: "The Story of Success",
  tags: [
    'Success', 'Psychology', 'Culture', 'Achievement', 'Sociology'
  ],
  author: "Malcolm Gladwell",
  datePublished: "2024-03-26",
  category: "Book Review",
  path: "/book-review/outliers",
  coverImage: "/images/outliers.jpg",
};

const Outliers = () => {
  const metadata = OutliersMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Outliers" challenges the conventional wisdom about success by looking beyond individual talent. Malcolm Gladwell explores how circumstances, timing, and even cultural background can play a significant role in shaping success stories. Through compelling anecdotes and research, Gladwell argues that success is not just about innate talent but also about seizing the right opportunities.
          </p>
          <br />
          <p>
            By examining the lives of famous "outliers"—people whose achievements are far outside the norm—Gladwell uncovers patterns and hidden forces that contribute to success. The book raises critical questions about fairness, opportunity, and the factors that truly make a difference in a person's ability to achieve greatness.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>10,000-Hour Rule:</strong> Gladwell popularizes the idea that it takes 10,000 hours of practice to achieve mastery in any field.</ListItem>
            <ListItem><strong>Opportunities and Timing:</strong> The book emphasizes how crucial opportunities and timing are in determining success.</ListItem>
            <ListItem><strong>Culture and Background:</strong> Gladwell discusses how cultural legacies and backgrounds shape an individual’s success trajectory.</ListItem>
            <ListItem><strong>Hidden Advantages:</strong> *Outliers* examines how unseen advantages, like birthdate or geography, can impact success.</ListItem>
            <ListItem><strong>Achievement vs. Luck:</strong> Gladwell shows how luck and circumstances play a larger role than we often acknowledge in stories of success.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "Success is not a random act. It arises out of a predictable and powerful set of circumstances and opportunities."
          </Quote>
          <Quote>
            "The people who stand before kings may look like they did it all by themselves. But in fact, they are invariably the beneficiaries of hidden advantages and extraordinary opportunities."
          </Quote>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "Outliers" made me rethink how we define success. The stories in the book clearly demonstrate that talent alone isn’t enough—there are many factors, like timing, cultural legacy, and access to opportunities, that have a profound effect on success. This is strongly connected to the concept of the "adjacent possible" from <a href="/book-review/where-good-ideas-come-from">"Where Good Ideas Come From"</a> by Steven Johnson, which highlights how new ideas and breakthroughs depend on the set of opportunities available at a given moment.
          </p>
          <br />
          <p>
            Just as the "adjacent possible" shows that innovation happens by building on what already exists and what is nearby in terms of opportunities, "Outliers" reinforces the idea that seizing the right opportunity at the right time is key to success. It’s not just about hard work—having the right conditions around you allows you to access your full potential.
          </p>
          <br />
          <p>
            This book reinforced my belief that we need to create environments where everyone has access to the right opportunities, not just those who are lucky enough to be born into them. It offers a powerful critique of the myths we tell about success and underlines the importance of broader societal responsibility in fostering achievement.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default Outliers;