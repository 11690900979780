import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0A1A2F; /* Dark blue background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #ffffff; /* White color */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #AAAAAA; /* Light gray */
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #888;
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #1C2D4F; /* Slightly lighter blue */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #E53935; /* Red color */
  border-bottom: 2px solid #E53935;
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #CCCCCC; /* Light grayish text */
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #2A3A5F; /* Darker shade of blue */
  border-left: 4px solid #E53935;
  border-radius: 4px;
  color: #AAAAAA; /* Light gray */
`;

// Personal Reflection styling
const PersonalNote = styled.div`
  background-color: #2E3A55;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  color: #CCCCCC;
  font-size: 1rem;
  line-height: 1.8;
`;

// Call-to-Action styling
const CallToAction = styled.p`
  margin-top: 1.5rem;
  color: #E53935;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #FFFFFF;
  }
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #E53935; /* Red background */
  color: #ffffff; /* White text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #E53935;
    color: #ff;
  }
`;

export const TheWorldForSaleMetadata = {
  title: "The World For Sale",
  description: "Money, Power and the Traders Who Barter the Earth’s Resources",
  tags: [
    'Economics', 'Trade', 'Resources', 'Globalization', 'Finance',
    'Markets', 'Commodities', 'Geopolitics', 'Business'
  ],
  author: "Javier Blas & Jack Farchy",
  datePublished: "2024-02-25",
  category: "Book Review",
  path: "/book-review/the-world-for-sale",
  coverImage: "/images/the_world_for_sale.jpg", // Ensure this path is correct
};

const TheWorldForSale = () => {
  const metadata = TheWorldForSaleMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "The World For Sale" takes readers behind the scenes of the world’s commodity trading industry, uncovering
            the stories of the powerful traders who control global markets. Javier Blas and Jack Farchy explore how
            these traders shape geopolitics and economies by bartering the world's resources, revealing the power
            dynamics and intrigues of the commodities market.
          </p>
          <p>
            This book provides an eye-opening look at the interconnected world of resource trading, from oil to metals,
            and how these traders influence nations, policies, and wealth. It’s an essential read for anyone interested
            in understanding the forces behind globalization and economic power.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Global Commodities Trade:</strong> The mechanisms behind global trade and how commodities are moved and exchanged.</ListItem>
            <ListItem><strong>Power and Influence:</strong> The political and economic power wielded by traders in controlling resources.</ListItem>
            <ListItem><strong>Geopolitics:</strong> The influence of resource trading on international politics and conflicts.</ListItem>
            <ListItem><strong>Economic Impact:</strong> How trading affects global economies and markets.</ListItem>
            <ListItem><strong>Market Manipulation:</strong> Exploring the ethical issues and manipulation tactics used in trading.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "The world is run by those who control its resources."
          </Quote>
          <Quote>
            "Traders do not just respond to the markets—they create them."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Note */}
      <PersonalNote>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading this book made me think deeply about the hidden forces that drive the global economy. While I always
            knew commodity trading was influential, the real-life stories and the sheer power that traders wield took me by surprise. 
            It feels unsettling to realize how much of our everyday lives are impacted by a few major players behind the scenes, 
            making deals that shift the balance of entire economies. 
          </p>
          <p>
            One thing that stayed with me is how interconnected politics and commodities are, and how these traders can either stabilize or disrupt economies.
            This book not only taught me about the mechanisms of trade but also raised questions about ethics and accountability in such a powerful industry.
          </p>
        </SectionContent>
      </PersonalNote>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default TheWorldForSale;