import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fcf6ab;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #d32f2f; /* Red color to match the cover */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #d32f2f; /* Red color */
  border-bottom: 2px solid #d32f2f;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f5cbcb;
  border-left: 4px solid #d32f2f;
  border-radius: 4px;
  color: #333;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #d32f2f;
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #d32f2f;
    color: #fff;
  }
`;

export const WeaponsOfMathDestructionMetadata = {
  title: "Weapons of Math Destruction",
  description: "How Big Data Increases Inequality and Threatens Democracy",
  tags: [
    'Data Science', 'Big Data', 'Ethics', 'Algorithmic Bias', 'Inequality',
    'Democracy', 'Artificial Intelligence', 'Machine Learning','Predictive Analytics',
    'Privacy', 'Transparency', 'Systems Thinking'
  ],
  author: "Cathy O'Neil",
  datePublished: "2024-08-12",
  category: "Book Review",
  path: "/book-review/weapons-of-math-destruction",
  coverImage: "/images/weapons_of_math_destruction.jpg",
};

const WeaponsOfMathDestruction = () => {
  const metadata = WeaponsOfMathDestructionMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            In "Weapons of Math Destruction", Cathy O'Neil explores the rise of big data algorithms and how they are increasingly being used to make high-stakes decisions, from predicting credit scores to determining prison sentences. However, these algorithms often operate as "black boxes," obscuring their workings and the biases they perpetuate.
          </p>
          <br />
          <p>
            O'Neil argues that many of these algorithms are "weapons of math destruction" (WMDs)—they are opaque, unregulated, and often discriminatory. She illustrates how these models reinforce social inequalities, impacting everything from education and employment to policing and justice.
          </p>
          <br />
          <p>
            The book calls for greater transparency, regulation, and accountability in the development and deployment of algorithms. O'Neil advocates for a more ethical approach to data science that takes into account the social consequences of mathematical models and strives for fairness and equity.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Algorithmic Bias:</strong> Algorithms can reinforce existing biases, leading to unfair and discriminatory outcomes.</ListItem>
            <ListItem><strong>Lack of Transparency:</strong> Many algorithms operate as "black boxes," making it difficult to understand their logic and challenge their fairness.</ListItem>
            <ListItem><strong>Impact on Inequality:</strong> Algorithms are often used in ways that disproportionately affect marginalized communities, exacerbating inequality.</ListItem>
            <ListItem><strong>Need for Regulation:</strong> The book argues for greater oversight and regulation to ensure that algorithms are used ethically.</ListItem>
            <ListItem><strong>Ethical Data Science:</strong> O'Neil calls for data scientists to be more aware of the social implications of their work and to prioritize transparency and fairness.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            “Big Data processes codify the past. They do not invent the future.”
          </Quote>
          <Quote>
            “Models, despite their reputation for impartiality, reflect goals and ideology.”
          </Quote>
          <Quote>
            “The truth is that big data is neither good nor bad. It’s a tool. What matters is how it is used.”
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            As someone aiming to become professionally involved in data science, "Weapons of Math Destruction" really opened my eyes to the ethical 
            implications of algorithms and machine learning models. Before reading this book, I saw algorithms primarily as tools of efficiency, 
            designed to make data-driven decisions faster and more accurately than humans. However, Cathy O'Neil’s arguments made me realize 
            that these tools are not always neutral or objective.
          </p>
          <br />
          <p>
            One of the biggest takeaways for me was how easily bias can become embedded in algorithms, especially when their workings are opaque 
            and unregulated. O'Neil’s examples of predictive policing and credit scoring showed me how algorithms can inadvertently reinforce 
            existing social inequalities, disproportionately affecting marginalized communities. This made me think more critically about the 
            models I may work with in the future and the potential consequences of their deployment.
          </p>
          <br />
          <p>
            The call for greater transparency and ethical responsibility resonated with me deeply. As I continue my journey into data science, 
            I realize that it’s not just about building effective models, but about ensuring that these models are fair, accountable, and do 
            not perpetuate harm. This book has motivated me to be more mindful of the ethical dimensions of the work we do as data scientists, 
            and to strive for fairness in the models we create.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default WeaponsOfMathDestruction;