import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaHourglassHalf } from 'react-icons/fa'; // Import the hourglass icon

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const Form = styled.form`
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  width: 100%;
  max-width: 600px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #0077b5;
  color: #fff;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #005f8d;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const LoadingIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const ThankYouMessage = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #0077b5;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show the spinner
    const additionalData = {
      browser: navigator.userAgent,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      language: navigator.language,
      platform: navigator.platform,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timestamp: new Date().toISOString(),
    };

    const finalData = { ...formData, ...additionalData };

    // Send the data to the Firebase Cloud Function endpoint
    fetch('https://us-central1-portfolio-8fb07.cloudfunctions.net/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(finalData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log('Email sent successfully!', data.message);
          setSubmitted(true); // Show thank you message
          setLoading(false); // Hide the spinner
          // Redirect to home page after 5 seconds
          setTimeout(() => {
            navigate('/');
          }, 5000);
        } else {
          console.error('Error sending email:', data.message);
          setLoading(false); // Hide the spinner
          alert('Failed to submit the form. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setLoading(false); // Hide the spinner
        alert('Failed to submit the form. Please try again.');
      });
  };

  return (
    <ContactContainer>
      {submitted ? (
        <ThankYouMessage>
          Thank you for your message! I will try to get back to you soon.<br />
          Redirecting to the homepage...
        </ThankYouMessage>
      ) : (
        <>
          <h1>Contact Me</h1>
          <br />
          <br />
          <Form onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={loading} // Disable input while loading
            />

            <label htmlFor="email">Email:</label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={loading} // Disable input while loading
            />

            <label htmlFor="message">Message:</label>
            <TextArea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
              disabled={loading} // Disable input while loading
            />

            <SubmitButton type="submit" disabled={loading}>
              {loading && (
                <LoadingIcon>
                  <FaHourglassHalf size={18} />
                </LoadingIcon>
              )}
              {loading ? 'Sending...' : 'Submit'}
            </SubmitButton>
          </Form>
          <p style={{ textAlign: 'center', marginTop: '1rem' }}>
          Alternatively, you can reach me directly via{' '}
          <a
            href="mailto:kyriakos.papadopoulos@acg.edu?subject=Contact%20Inquiry&body=Hello%20Kyriakos,%0D%0A%0D%0AI%20would%20like%20to%20inquire%20about...%0D%0A%0D%0AThank%20you,%0D%0A[Your%20Name]"
            style={{ color: '#0077b5', textDecoration: 'underline' }}
          >
            email
          </a>.
        </p> 
        </>
      )}
    </ContactContainer>
  );
};

export default Contact;