import React from 'react';
import styled from 'styled-components';

const ProjectDetailContainer = styled.div`
  padding: 3rem 2rem;
  background-color: #f0f9f3;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 1.5rem 0.5rem;
  }
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #003300;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Date = styled.p`
  font-size: 0.9rem;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const TagsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: #22b573;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const Image = styled.img`
  display: block; 
  max-width: 100%;
  height: auto;
  margin: 0 auto 2rem auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 85%;
  }
`;

const Section = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #003300;
  margin-bottom: 1rem;
  border-bottom: 2px solid #22b573;
  padding-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const List = styled.ul`
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #444;

  @media (max-width: 480px) {
    padding-left: 1rem;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  line-height: 1.6;

  @media (max-width: 768px) {
    margin-bottom: 0.4rem;
  }
`;

const NotebookEmbed = styled.iframe`
  width: 100%;
  height: 800px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;

  @media (max-width: 480px) {
    height: 400px;
    margin: 1.5rem 0;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  margin: 2rem 0;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: block;

  @media (max-width: 480px) {
    margin: 1.5rem 0;
  }
`;

const Button = styled.button`
  display: flex;
  margin: 0 auto;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #22b573;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;

  &:hover {
    background-color: #1a8f63;
  }

  @media (max-width: 768px) {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.85rem;
  }
`;

const SpotifyAPIProject = () => {
  const tags = ['Python', 'API', 'Spotify', 'Automation', 'Image Generation'];
  const datePublished = '2024-08-15';

  return (
    <ProjectDetailContainer>
      <Header>
        <Title>Spotify Monthly Playlist Automation</Title>
        <Date>Published on: {datePublished}</Date>
      </Header>
      <Image src="/images/monthly_playlists.jpg" alt="Spotify Monthly Playlists" />
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>
      <Section>
        <SectionTitle>Overview</SectionTitle>
        <Text>
          This project automates the creation of monthly Spotify playlists based on your listening history and generates unique playlist covers for each month.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Features</SectionTitle>
        <List>
          <ListItem>Automated Playlist Creation based on streaming history.</ListItem>
          <ListItem>Custom Cover Generation using radial gradient backgrounds and noise texture.</ListItem>
          <ListItem>Dynamic Font Selection for optimal contrast and readability.</ListItem>
          <ListItem>Ensures Unique Colors for each playlist cover.</ListItem>
          <ListItem>Optionally Exclude songs from specific playlists in your library.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Dependencies</SectionTitle>
        <List>
          <ListItem>Python 3.7+</ListItem>
          <ListItem>Spotipy for Spotify API interaction</ListItem>
          <ListItem>Pandas and NumPy for data manipulation</ListItem>
          <ListItem>Pillow for image creation and manipulation</ListItem>
          <ListItem>dotenv for environment variable management</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Workflow</SectionTitle>
        <Text>
          The project is divided into two parts: generating playlists and creating covers. It uses Jupyter Notebooks to manage each process.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Notebooks</SectionTitle>
        <NotebookEmbed 
          src="/embeds/wrapped_months.html"
          title="Jupyter Notebook"
        />
        <hr /> 
        <NotebookEmbed
          src="/embeds/cover_generation.html"
          title="Jupyter Notebook"
        />
      </Section>

      <Section>
        <SectionTitle>Results</SectionTitle>
        <Video controls>
          <source src="/videos/spotify_demo.mov" type="video/mp4" />
          <source src="/videos/spotify_demo.mov" type="video/quicktime" />
          Your browser does not support the video tag.
        </Video>
      </Section>

      <Section>
        <SectionTitle>Known Issues</SectionTitle>
        <Text>
          The project currently requires the Extended Streaming History Dataset from Spotify, as the general export lacks track IDs needed for playlist creation.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Future Plans</SectionTitle>
        <Text>
          Plans include automating cover uploads to Spotify and adding options for weekly or seasonal playlist segmentation.
        </Text>
      </Section>

      <Button onClick={() => window.history.back()}>Back to Projects</Button>
    </ProjectDetailContainer>
  );
};

// Export metadata for use in the Projects page
export const spotifyAPIMetadata = {
  title: 'Spotify Monthly Playlist Automation',
  description: 'Automates Spotify playlist creation and cover generation based on your listening history.',
  tags: ['Python', 'API', 'Spotify', 'Automation', 'Image Generation'],
  languages: ['Python'],
  datePublished: '2024-08-15',
  path: '/projects/monthly-playlists',
  coverImage: '/images/monthly_playlists.jpg'
};

export default SpotifyAPIProject;