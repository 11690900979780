import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2E2B40; /* Dark purple background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #f1e4e8; /* Light pink color */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #b0a1ba; /* Light blue-gray */
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #888;
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #3C3754; /* Slightly lighter purple */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #f1e4e8; /* Light pink color */
  border-bottom: 2px solid #f1e4e8;
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #d0c9d6; /* Light grayish text */
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #4a465b; /* Darker shade of purple */
  border-left: 4px solid #f1e4e8;
  border-radius: 4px;
  color: #b0a1ba; /* Light blue-gray */
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #f1e4e8; /* Light pink background */
  color: #2E2B40; /* Dark purple text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #3C3754;
    color: #fff;
  }
`;

export const WhereGoodIdeasComeFromMetadata = {
  title: "Where Good Ideas Come From",
  description: "The Natural History of Innovation",
  tags: [
    'Innovation', 'Creativity', 'History', 'Science', 'Technology',
    'Ideas', 'Network', 'Collaboration', 'Evolution'
  ],
  author: "Steven Johnson",
  datePublished: "2023-11-02",
  category: "Book Review",
  path: "/book-review/where-good-ideas-come-from",
  coverImage: "/images/where_good_ideas_come_from.jpg", // Ensure this path is correct
};

const WhereGoodIdeasComeFrom = () => {
  const metadata = WhereGoodIdeasComeFromMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />

      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            In "Where Good Ideas Come From", Steven Johnson explores the origins of innovation and creativity, uncovering
            the patterns that lead to breakthroughs in technology, science, and art. He emphasizes the importance of connectivity,
            collaboration, and openness in fostering a culture of innovation.
          </p>
          <br />
          <p>
            Johnson delves into historical examples and current theories, showing how ideas develop in networks and ecosystems
            rather than in isolation. He argues that environments where diverse concepts can collide and recombine are the most
            conducive to generating groundbreaking ideas.
          </p>
          <br />
          <p>
            Through this insightful book, readers gain an understanding of how we can cultivate our own creativity and harness
            the power of collaborative networks to drive progress and evolution in various fields.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>The Adjacent Possible:</strong> The concept of expanding the boundaries of innovation by exploring adjacent ideas and opportunities.</ListItem>
            <ListItem><strong>Liquid Networks:</strong> The power of collaboration and idea sharing in dynamic, flexible environments.</ListItem>
            <ListItem><strong>Slow Hunch:</strong> How long incubation periods can lead to breakthroughs as ideas gradually mature.</ListItem>
            <ListItem><strong>Serendipity:</strong> The role of unexpected connections and discoveries in innovation.</ListItem>
            <ListItem><strong>Error and Experimentation:</strong> Embracing failure and experimentation as essential to the creative process.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "Chance favors the connected mind."
          </Quote>
          <Quote>
            "The trick to having good ideas is not to sit around in glorious isolation and try to think big thoughts. The trick is to get more parts on the table."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "Where Good Ideas Come From" made me rethink how creativity works. Steven Johnson’s breakdown of concepts like
            "the adjacent possible" and "liquid networks" gave me a fresh perspective on how ideas don’t usually come out of nowhere,
            but rather evolve from existing contexts, collaborations, and connections.
          </p>
          <br />
          <p>
            What really struck me was the concept of the "slow hunch"—the idea that sometimes good ideas take time to form and mature,
            often lingering in the background before they finally become clear. This shifted my mindset about how I approach my own ideas.
            Instead of forcing quick results, I now see the value in letting ideas simmer and develop over time, knowing that the right
            connections will eventually come together.
          </p>
          <br />
          <p>
            Another powerful takeaway for me was the role of serendipity in the creative process. Johnson’s focus on unexpected, 
            sometimes random discoveries reminded me that good ideas don’t always follow a straight path. They often come from
            chance encounters, mistakes, or unplanned connections. This made me more open to exploring ideas that might seem 
            unrelated at first but could later become the key to something larger.
          </p>
          <br />
          <p>
            Overall, this book shifted my approach to problem-solving and creativity. It reminded me to embrace collaboration, 
            stay open to serendipity, and trust that great ideas often come from the most unexpected sources.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default WhereGoodIdeasComeFrom;