import React from 'react';
import styled from 'styled-components';

const ProjectDetailContainer = styled.div`
  padding: 3rem 1.5rem;
  background-color: ##f2f7ff; /* Light background for readability */
  min-height: 100vh;
  max-width: 1100px;
  margin: 0 auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  border-radius: 12px;
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 2.5rem;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: #1c1c1c; /* Darker shade for titles */
  margin-bottom: 0.5rem;
`;

const Date = styled.p`
  font-size: 1rem;
  color: #888; /* Neutral grey for dates */
`;

const TagsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center; /* Align tags left for readability */
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: #0073e6; /* Blue for a more modern look */
  color: white;
  padding: 0.3rem 0.8rem;
  border-radius: 16px;
  font-size: 0.85rem;
`;

const Image = styled.img`
  display: block;
  max-width: 80%;
  height: auto;
  margin: 0 auto 2rem auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow */
`;

const Section = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.2rem;
  border-bottom: 3px solid #0073e6;
  padding-bottom: 0.3rem;
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444; /* Softer color for better readability */
  margin-bottom: 1.5rem;
`;

const List = styled.ul`
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #444;
`;

const ListItem = styled.li`
  margin-bottom: 0.8rem; /* Add more spacing between bullet points */
  line-height: 1.6;
`;

const Button = styled.button`
  display: inline-block;
  margin: 3rem 0;
  padding: 0.9rem 2.2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0073e6; /* Blue for buttons */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #005bb5; /* Darker blue for hover */
  }
`;

const BbdaClustering = () => {
  const tags = ['Python', 'Data Preprocessing', 'Unsupervised Learning', 'Clustering', 'Dimensionality Reduction', 'PCA', 'K-Means', 'Elbow Method', 'Spotify'];
  const datePublished = '2024-07-20';

  return (
    <ProjectDetailContainer>
      <Header>
        <Title>Data-Driven Insights for Music Personalization</Title>
        <Date>Published on: {datePublished}</Date>
      </Header>
      <Image src="/images/bbda_clustering.png" alt="Sentiment Analysis" />
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>
      <br />
      <br /> 
      <br /> 
      <Section>
        <SectionTitle>Collaborators</SectionTitle>
        <List>
          <ListItem><a href="https://www.linkedin.com/in/alexandra-segu/" target="_blank" rel="noopener noreferrer">Alexandra Segkou</a></ListItem>
          <ListItem><a href="https://www.linkedin.com/in/jimmysiloglou/" target="_blank" rel="noopener noreferrer">Dimitris Mysiloglou</a></ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Overview</SectionTitle>
        <Text>
          This project was conducted as part of the Data Science Bootcamp from Big Blue Data Academy. It was the fourth and final practice project of the course, completed in around 5 hours on the 20th of July 2024. The topic was unsupervised machine learning, and my teammates and I performed clustering on a dataset of Spotify's top 2000 songs, containing audio features such as Danceability, BPM, Liveness, and Valence.
        </Text>
      </Section>
      
      <Section>
        <SectionTitle>Objectives</SectionTitle>
        <Text>
          The primary objectives of this project were:
        </Text>
        <List>
          <ListItem>To explore and understand the key characteristics of these top tracks.</ListItem>
          <ListItem>To cluster songs based on their audio features to identify distinct groups.</ListItem>
          <ListItem>Identify patterns and group similar songs to better understand our music collection.</ListItem>
          <ListItem>Make informed decisions for marketing, playlist curation, and audience engagement based on the analysis.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Tools Used</SectionTitle>
        <Text>
          The following tools and libraries were utilized in this project:
        </Text>
        <List>
          <ListItem><strong>Python</strong></ListItem>
          <ListItem><strong>Pandas</strong> and <strong>NumPy</strong> for data manipulation</ListItem>
          <ListItem><strong>Matplotlib</strong>, <strong>Seaborn</strong>, and <strong>WordCloud</strong> for visualizations</ListItem>
          <ListItem><strong>Scikit-learn</strong> for preprocessing, decomposition, and clustering</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Methodology</SectionTitle>
        <Text>
          <p>The dataset was extracted from Spotify by @plamere and downloaded by us from <a href="https://www.kaggle.com/datasets/iamsumat/spotify-top-2000s-mega-dataset/data" target="_blank" rel="noopener noreferrer">Kaggle</a>. We applied clustering techniques to group songs based on audio features.</p>
          <br />
          <p>The steps involved in the analysis were:</p>
          <br />
          <List>
            <ListItem><strong>Data Preprocessing</strong>: Cleaning and scaling the data for analysis.</ListItem>
            <ListItem><strong>Dimensionality Reduction</strong>: Using PCA to reduce the number of features.</ListItem> 
            <ListItem><strong>Elbow Method</strong>: Determining the optimal number of clusters.</ListItem>
            <ListItem><strong>Clustering</strong>: Applying the K-Means algorithm to group songs.</ListItem>
            <ListItem><strong>Analysis</strong>: Interpreting the clusters and identifying key characteristics.</ListItem >
          </List>
        </Text>
      </Section>

      <Section>
        <SectionTitle>Results</SectionTitle>
        <Text>
          <p>Our Approach yielded three distinct clusters of songs based on their audio features:</p>
          <br />
          <List>
            <ListItem><strong>Cluster 0:</strong> More Beat Per Minute (BPM), energy, loudness, and liveness, with least acousticness.</ListItem>
            <ListItem><strong>Cluster 1:</strong> Highest valence among the clusters, indicating more positive, happy-sounding tracks. High danceability.</ListItem>
            <ListItem><strong>Cluster 2:</strong> More acousticness, quieter with less valence and energy, and less liveness. </ListItem>
          </List> 
        </Text>

      </Section>

      <Section>
        <SectionTitle>Presentation</SectionTitle>
        <Text>
          Below is an embedded presentation summarizing the findings from the sentiment and topic analysis project.
        </Text>
        <div style={{ margin: '2.5rem 0', display: 'flex', justifyContent: 'center' }}>
          <iframe
            title="Unsupervised Learning Presentation"
            src="https://docs.google.com/presentation/d/e/2PACX-1vTdyJ2WJiyp-Bc-xErrNIQbUvxwn38MONmd4zc8R49QmaX0rtYfjQuUq0vO6Sj46w/embed?start=false&loop=false&delayms=3000"
            width="100%"  // Full width for responsiveness
            height="569"  // Adjust height for good viewing ratio
            allowFullScreen
            mozAllowFullScreen
            webkitAllowFullScreen
          ></iframe>
        </div>
      </Section>

      <Section>
        <SectionTitle>Conclusion</SectionTitle>
        <Text>
          The project successfully grouped the songs into three clusters based on their audio features. These clusters were analyzed and visualized to understand the characteristics of each group. This information can inform decisions for marketing, playlist curation, and audience engagement.
        </Text>
      </Section>

      <Button onClick={() => window.history.back()}>Back to Projects</Button>
    </ProjectDetailContainer>
  );
};

export const BbdaClusteringMetadata = {
  title: 'Data-Driven Insights for Music Personalization',
  description: 'Utilized unsupervised machine learning to cluster Spotify\'s top 2000 songs based on their audio features.',
  tags: ['Python', 'Data Preprocessing', 'Unsupervised Learning', 'Clustering', 'Dimensionality Reduction', 'PCA', 'K-Means', 'Elbow Method', 'Spotify'],
  languages: ['Python'],
  datePublished: '2024-07-20',
  path: '/projects/bbda-clustering',
  coverImage: 'images/bbda_clustering.png'
};

export default BbdaClustering;