import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #d8472b; /* Red-orange color to match the cover */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #555555; /* Dark gray for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555; /* Dark gray for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #f9f9f9; /* Light gray background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #d8472b; /* Red-orange color for section title */
  border-bottom: 2px solid #d8472b;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark text for readability */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f9e7de; /* Soft orange background for quotes */
  border-left: 4px solid #d8472b;
  border-radius: 4px;
  color: #333333; /* Dark gray for quote text */
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #d8472b; /* Red-orange for tags */
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #d8472b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #008bc4;
  }
`;

export const InvisibleWomenMetadata = {
  title: "Invisible Women",
  description: "Exposing Data Bias in a World Designed for Men",
  tags: [
    'Gender Equality', 'Feminism', 'Algorithmic Bias', 'Technology',
    'Health', 'Workplace', 'Social Justice'
  ],
  author: "Caroline Criado Perez",
  datePublished: "2024-06-20",
  category: "Book Review",
  path: "/book-review/invisible-women",
  coverImage: "/images/invisible_women.jpeg",
};

const InvisibleWomen = () => {
  const metadata = InvisibleWomenMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Invisible Women" exposes the shocking extent of gender bias in a world that has been predominantly designed for men. Caroline Criado Perez uses extensive research and data to show how women are often left out of key areas like urban planning, medical research, and technology design. The book illustrates how this oversight negatively affects women’s lives and calls for a more inclusive approach to data collection and design.
          </p>
          <br />
          <p>
            Through a compelling blend of case studies and hard data, Perez challenges the default assumption that men represent the ‘universal’ human experience, arguing that failing to consider women in data leads to policies and products that disadvantage half the population.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Data Bias:</strong> The book uncovers how data collection methodologies often ignore women’s experiences, leading to biased outcomes.</ListItem>
            <ListItem><strong>Gender Gap:</strong> Perez highlights the disparity between men’s and women’s access to health, technology, and resources due to biased systems.</ListItem>
            <ListItem><strong>Workplace Inequality:</strong> The gender bias in how workplaces are structured and how this affects women’s productivity and safety.</ListItem>
            <ListItem><strong>Urban Planning:</strong> How cities are designed around men’s needs, leading to challenges for women, particularly in terms of safety and accessibility.</ListItem>
            <ListItem><strong>Health and Safety:</strong> The consequences of using male-centered data in medical research and safety testing.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "The lives of men have been taken to represent those of humans overall."
          </Quote>
          <Quote>
            "When we exclude half of humanity from the data, we are only telling half the story."
          </Quote>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "Invisible Women" opened my eyes to the pervasive nature of gender bias in everyday life, from the design of office spaces to the technologies we use daily. The book made me realize that while much progress has been made for gender equality, data collection still severely underrepresents women, leading to systemic issues in health, safety, and access to opportunities.
          </p>
          <br />
          <p>
            One of the most striking takeaways for me was how gender data gaps aren’t just inconvenient—they can be life-threatening, particularly in areas like healthcare and safety. The stories and studies Perez presents are both shocking and illuminating.
          </p>
          <br />
          <p>
            As someone interested in data science, "Invisible Women" has reinforced the importance of inclusivity in data collection and analysis. It’s not just about equality; it’s about designing systems that work for everyone.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default InvisibleWomen;