import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #b8314b; /* Red tone to match the cover */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #555555; /* Dark gray for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555; /* Dark gray for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #f9f9f9; /* Light gray background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #b8314b; /* Red tone for section title */
  border-bottom: 2px solid #b8314b;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark text for readability */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f6e0e6; /* Soft pink background for quotes */
  border-left: 4px solid #b8314b;
  border-radius: 4px;
  color: #333333; /* Dark gray for quote text */
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #b8314b; /* Red for tags */
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #b8314b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #000;
  }
`;

export const TalkingToStrangersMetadata = {
  title: "Talking to Strangers",
  description: "What We Should Know about the People We Don’t Know",
  tags: [
    'Psychology', 'Social Behavior', 'Trust', 'Communication', 'Relationships',
    'Human Nature', 'Bias', 'Perception', 'Society'
  ],
  author: "Malcolm Gladwell",
  datePublished: "2023-08-23",
  category: "Book Review",
  path: "/book-review/talking-to-strangers",
  coverImage: "/images/talking_to_strangers.jpg",
};

const TalkingToStrangers = () => {
  const metadata = TalkingToStrangersMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Talking to Strangers" explores the miscommunication and misunderstandings we often face when interacting with people we don’t know. Malcolm Gladwell presents real-world cases, offering insights into how our assumptions about trust, truth, and behavior can lead to severe consequences. Through stories about infamous events, he questions the systems we use to judge others and how often they fail.
          </p>
          <br />
          <p>
            Gladwell dissects human behavior, using psychological research to explain why we are not as good at reading others as we think. He examines moments where our biases and misinterpretations have led to disasters in personal and societal interactions.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Misperceptions:</strong> The book emphasizes how we often fail to accurately understand people we don't know.</ListItem>
            <ListItem><strong>Trust and Deception:</strong> Gladwell delves into the psychology of trust and why we struggle to spot deception.</ListItem>
            <ListItem><strong>Judgment Errors:</strong> He examines how errors in judgment can lead to devastating consequences.</ListItem>
            <ListItem><strong>Truth Default Theory:</strong> Explores why humans are predisposed to believe others by default, even when deception is possible.</ListItem>
            <ListItem><strong>Bias and Prejudice:</strong> The role of unconscious bias in distorting our understanding of others.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "The right way to talk to strangers is with caution and humility."
          </Quote>
          <Quote>
            "We have strategies for dealing with strangers that are deeply flawed."
          </Quote>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "Talking to Strangers" made me rethink how I approach interactions with people I don’t know. It’s easy to trust in our ability to judge character, but Gladwell’s exploration of historical events and psychological studies exposes how often we get it wrong.
          </p>
          <br />
          <p>
            The book made me more aware of my own biases and how quickly I can misinterpret others. It reinforces the importance of being open-minded and cautious when interacting with strangers, and not jumping to conclusions based on initial impressions.
          </p>
          <br />
          <p>
            One of the most impactful lessons was the concept of 'truth-default'—the tendency to believe people by default until proven otherwise. It explained why we are often blind to deception in both personal and professional relationships. It’s a powerful reminder to approach interactions with humility and understanding.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default TalkingToStrangers;