import React from 'react';
import styled from 'styled-components';

const ProjectDetailContainer = styled.div`
  padding: 3rem 2rem;
  background-color: #f7f7f7;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 1.5rem 0.5rem;
  }
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Date = styled.p`
  font-size: 0.9rem;
  color: #777;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const TagsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const Tag = styled.span`
  background-color: #3b3b3b;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const Image = styled.img`
  display: block; 
  max-width: 800px;
  height: auto;
  margin: 0 auto 2rem auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 85%;
  }
`;

const Section = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #333333;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3b3b3b;
  padding-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const List = styled.ul`
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #444;

  @media (max-width: 480px) {
    padding-left: 1rem;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  line-height: 1.6;

  @media (max-width: 768px) {
    margin-bottom: 0.4rem;
  }
`;

const NotebookEmbed = styled.iframe`
  width: 100%;
  height: 800px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;

  @media (max-width: 480px) {
    height: 400px;
    margin: 1.5rem 0;
  }
`;

const Button = styled.button`
  display: flex;
  margin: 0 auto;  /* centers the button horizontally */
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #3b3b3b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }

  @media (max-width: 768px) {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.85rem;
  }
`;

const SpotifyPlaylistCreatorProject = () => {
  const tags = ['Python', 'API', 'Spotify', 'Automation'];
  const datePublished = '2024-08-03';

  return (
    <ProjectDetailContainer>
      <Header>
        <Title>Spotify Sentence To Playlist Automoation</Title>
        <Date>Published on: {datePublished}</Date>
      </Header>
      <Image src="/images/psycho.jpg" alt="Spotify Playlist Creator" />
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>
      
      <Section >
        <SectionTitle style={{ marginTop: '2rem' }}>Overview</SectionTitle>
        <Text>
          This fun and somewhat quirky project was born out of exploring the Spotify Web API's capabilities. The inspiration behind the project comes from the idea of a "crazy" ex trying to send messages to their blocked ex by crafting Spotify playlists, since they’ve been blocked on other platforms.
        </Text>
        <Text>
          The project demonstrates how to turn any sentence into a Spotify playlist by searching for song titles that match the words in the sentence. If no match is found, the app adds a placeholder track to complete the message. It’s a lighthearted exploration of automation and creative ways to communicate, illustrating how technology can bring unexpected and humorous ideas to life.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Features</SectionTitle>
        <List>
          <ListItem><strong>API Interaction</strong>: Utilizes the Spotify Web API to search for tracks and create playlists.</ListItem>
          <ListItem><strong>Sentence to Playlist</strong>: Converts a sentence into a playlist by searching for song titles that match the words in the sentence.</ListItem>
          <ListItem><strong>Contextual Search</strong>: Tries to find matches for up to 4 consecutive words to increase the relevance of tracks.</ListItem>
          <ListItem><strong>Dynamic Configuration</strong>: Uses the optimal configuration of word combinations to maximize matched tracks.</ListItem>
          <ListItem><strong>Placeholder Track</strong>: Adds a placeholder when no match is found for a word.</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Dependencies</SectionTitle>
        <List>
          <ListItem>Python 3.x (Developed with Python 3.7.6)</ListItem>
          <ListItem>Spotipy library for Spotify API interaction</ListItem>
          <ListItem>dotenv for environment variable management</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>Workflow</SectionTitle>
        <Text>
          The program cleans the sentence input, searches for matching tracks, tries different configurations for the best match, and creates a Spotify playlist.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Notebook</SectionTitle>
        <NotebookEmbed 
          src="/embeds/psycho_playlists.html"
          title="Jupyter Notebook"
        />
      </Section>

      <Section>
        <SectionTitle>Results</SectionTitle>
        <Image src="/images/psycho_demo.jpeg" alt="Spotify Playlist Result" />
      </Section>

      <Section>
        <SectionTitle>Known Issues</SectionTitle>
        <Text>
            There are a few limitations in the current version of the playlist creator:
        </Text>
        <List>
            <ListItem>
            <strong>Common Words</strong>: Words like "and," "the," or "I" are often too generic, resulting in irrelevant or no matches. These words can significantly reduce the accuracy of the playlist generation.
            </ListItem>
            <ListItem>
            <strong>Unusual Words</strong>: Small or unusual words, especially those that don't commonly appear in song titles, may not have matches in Spotify’s catalog, leading to more placeholder tracks being added.
            </ListItem>
            <ListItem>
            <strong>API Limitations</strong>: The Spotify API may return incomplete results, especially for less popular tracks. This can affect the ability to find matches for more obscure song titles.
            </ListItem>
        </List>
        </Section>

      <Section>
        <SectionTitle>Possible Improvements</SectionTitle>
        <Text>
            Several enhancements could be made to improve the overall functionality and accuracy of the playlist generation:
        </Text>
        <List>
            <ListItem>
            <strong>Fuzzy Matching</strong>: Implementing fuzzy matching could help find songs with titles that are close but not exact matches to the words in the sentence. This would allow for more flexibility in the matching process and reduce the number of unmatched words.
            </ListItem>
            <ListItem>
            <strong>Thesaurus Integration</strong>: Using a thesaurus or synonym-based dictionary could expand the search options for words that have no exact matches. For example, if a word like "happy" doesn't yield results, the program could try similar words like "joyful" or "glad."
            </ListItem>
            <ListItem>
            <strong>Improved Placeholder Tracks</strong>: Instead of using generic placeholder tracks, there could be genre-based or mood-based placeholder suggestions that align with the overall theme of the sentence.
            </ListItem>
            <ListItem>
            <strong>User-Defined Preferences</strong>: Allow users to input preferences like favorite genres, artists, or moods, so that these preferences could guide track selection in ambiguous cases.
            </ListItem>
        </List>
        <Text>
            <strong>Disclaimer:</strong> Many of these improvements, particularly fuzzy matching and thesaurus integration, could significantly increase the runtime of the program and lead to potential timeouts or rate-limiting issues with the Spotify API, depending on how they are implemented.
        </Text>
        </Section>

      <Button onClick={() => window.history.back()}>Back to Projects</Button>
    </ProjectDetailContainer>
  );
};

// Export metadata for use in the Projects page
export const SpotifyPlaylistCreatorMetadata = {
    title: 'Spotify Sentence To Playlist Automoation',
    description: 'Converts a sentence into a Spotify playlist by finding matching songs using the Spotify Web API.',
    tags: ['Python', 'API', 'Spotify', 'Automation'],
    languages: ['Python'],
    datePublished: '2024-08-03',
    path: '/projects/psycho-playlists',
    coverImage: '/images/psycho.jpg'
  };

export default SpotifyPlaylistCreatorProject;