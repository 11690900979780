// components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #000000;
  color: #f8f9fa;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap */
  font-size: 0.9rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const FooterText = styled.div`
  flex: 1;
  text-align: center;
  margin: 0.5rem 0;
  position: relative;

  @media (max-width: 600px) {
    order: 2;
    margin-top: 1rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;

  @media (max-width: 600px) {
    order: 3;
    margin-top: 1rem;
  }
`;

const IconLink = styled.a`
  color: #f8f9fa;
  font-size: 1.5rem;
  transition: color 0.2s;
  display: inline-flex;
  align-items: center;
  position: relative;

  &:hover {
    color: #0077b5;
  }

  svg {
    pointer-events: none;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        &copy; {new Date().getFullYear()}
        <a
          href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#f8f9fa',
            textDecoration: 'none',
            marginLeft: '5px',
            position: 'relative',
          }}
        >
          Kyriakos Papadopoulos. All rights reserved.
        </a>
      </FooterText>
      <SocialLinks>
        <IconLink
          href="https://www.linkedin.com/in/papadopoulos-kyriakos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </IconLink>
        <IconLink
          href="https://github.com/kyriakos-papadopoulos"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub />
        </IconLink>
      </SocialLinks>
    </FooterContainer>
  );
};

export default Footer;