import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: #ff7f50; /* Orange background matching the book cover */
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #000000; /* Black for the bold title */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #ffffff; /* White for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #ffffff; /* White for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff; /* White background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #000000; /* Black for section titles */
  border-bottom: 2px solid #000000;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark gray for content */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f0f0f0; /* Light gray for quotes */
  border-left: 4px solid #000000; /* Black border for quote */
  border-radius: 4px;
  color: #333333;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #000000; /* Black for tags */
  color: #ffffff; /* White text for tags */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #008ad4;
    color: #000;
  }
`;

export const HowTheWorldReallyWorksMetadata = {
  title: "How the World Really Works",
  description: "A guide to understanding the world's systems and structures by Vaclav Smil.",
  tags: [
    'Science', 'Technology', 'Economy', 'Energy', 'Environment', 'Sustainability', 'Climate',
    'History', 'Innovation', 'Globalization'
  ],
  author: "Vaclav Smil",
  datePublished: "2024-01-24",
  category: "Book Review",
  path: "/book-review/how-the-world-really-works",
  coverImage: "/images/how_the_world_really_works.jpg", // Ensure this path is correct
};

const HowTheWorldReallyWorks = () => {
  const metadata = HowTheWorldReallyWorksMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            In "How the World Really Works", Vaclav Smil delves into the mechanisms and structures that shape our modern world.
            From energy and technology to food and economy, Smil provides insights into the critical processes that sustain society.
          </p>
          <br />
          <p>
            The book offers an in-depth look at the complexities of global systems and challenges commonly held beliefs about progress
            and sustainability. Smil's scientific approach is both enlightening and provocative, urging readers to question their
            assumptions and gain a deeper understanding of the forces that drive our world.
          </p>
          <br />
          <p>
            With a focus on factual evidence and historical perspective, Smil discusses the implications of energy consumption,
            technological advancements, and the environmental impact of human activities. The book serves as a guide for those
            looking to comprehend the past, present, and future of our planet.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Energy Systems:</strong> An exploration of global energy consumption and its impact on economies and environments.</ListItem>
            <ListItem><strong>Technological Advances:</strong> How technology shapes society and influences the global economy.</ListItem>
            <ListItem><strong>Global Processes:</strong> Insights into the interconnected systems that sustain modern civilization.</ListItem>
            <ListItem><strong>Environmental Impact:</strong> The consequences of human activity on the planet's ecosystems and climate.</ListItem>
            <ListItem><strong>Sustainability and Policy:</strong> A look into the future and the measures needed for sustainable development.</ListItem>
            <ListItem><strong>Scientific Perspective:</strong> The importance of understanding scientific evidence in shaping informed decisions.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "Understanding the world’s complexity is the first step in learning to navigate its challenges."
          </Quote>
          <Quote>
            "Energy is the foundation of modern civilization, and our dependence on it has shaped the world as we know it."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "How the World Really Works" a few months after finishing <a href="/book-review/the-sixth-extinction">"The Sixth Extinction"</a> was a natural progression 
            in understanding the complex relationship between human activity and the environment. Where "The Sixth Extinction" highlighted the dire consequences of 
            biodiversity loss and environmental degradation, Smil's book dives deeper into the underlying systems—like energy consumption and technology—that 
            have led us to this point.
          </p>
          <br />
          <p>
            One of the major takeaways for me was how closely energy and environmental impact are intertwined. Smil provides a sobering reminder that while we 
            focus on the need for sustainability, our dependence on fossil fuels and energy-intensive technologies makes progress much more difficult than it appears.
            This built on the ideas I encountered in *The Sixth Extinction*, where the human footprint on ecosystems is a recurring theme.
          </p>
          <br />
          <p>
            The most eye-opening aspect of Smil’s work is his focus on data and evidence. He systematically breaks down myths about progress, revealing the challenges 
            of transforming our energy systems and the scale of what is required for true sustainability. It gave me a much clearer understanding of the complexity 
            of the problem, especially when thinking about how to balance technological growth with environmental protection.
          </p>
          <br />
          <p>
            Both books have deepened my appreciation for the intricate systems that sustain life and how interconnected they all are. Smil’s scientific approach, 
            combined with the stark warnings in "The Sixth Extinction", has left me with a renewed sense of urgency to support more sustainable practices in my own life 
            and to advocate for policies that prioritize long-term environmental health.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default HowTheWorldReallyWorks;