import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fffacd; /* Light yellow background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #000; /* Black color */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #888;
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #000; /* Black color */
  border-bottom: 2px solid #000; /* Black border */
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f0f0f0;
  border-left: 4px solid #000;
  border-radius: 4px;
  color: #555;
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #000; /* Black background */
  color: yellow; /* Yellow text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fffacd;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #dfd700;
    color: #000;
  }
`;

export const HappinessHypothesisMetadata = {
  title: "The Happiness Hypothesis",
  description: "Putting Ancient Wisdom to the Test of Modern Science",
  tags: [
    'Psychology', 'Happiness', 'Wisdom', 'Science',
    'Self Help', 'Philosophy', 'Mindfulness'
  ],
  author: "Jonathan Haidt",
  datePublished: "2023-12-18",
  category: "Book Review",
  path: "/book-review/happiness-hypothesis",
  coverImage: "/images/happiness_hypothesis.jpg", // Ensure this path is correct
};

const HappinessHypothesis = () => {
  const metadata = HappinessHypothesisMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />

      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            In "The Happiness Hypothesis", Jonathan Haidt explores how ancient philosophies and modern science converge
            to provide a comprehensive understanding of happiness and well-being. Haidt delves into ancient wisdom from
            thinkers like Buddha, Plato, and Marcus Aurelius and tests their teachings with the lens of contemporary psychology.
          </p>
          <br />
          <p>
            Haidt introduces concepts such as the "divided mind" and how our rational and emotional brains are often in conflict,
            much like an elephant and its rider. He also discusses the importance of relationships, the pursuit of meaning,
            and how we can develop practices that lead to a fulfilled life.
          </p>
          <p>
            Through various chapters, Haidt presents key ideas on the science of happiness, showing how we can integrate ancient
            practices like mindfulness into our daily lives to cultivate a deeper sense of contentment.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>The Divided Mind:</strong> The conflict between the emotional and rational aspects of the mind.</ListItem>
            <ListItem><strong>Ancient Wisdom:</strong> Insights from philosophy and religion about living a good life.</ListItem>
            <ListItem><strong>Modern Science:</strong> How current psychology research supports or refutes ancient teachings.</ListItem>
            <ListItem><strong>Mindfulness and Meditation:</strong> The role of mindful practices in enhancing well-being.</ListItem>
            <ListItem><strong>The Pursuit of Meaning:</strong> Finding purpose and fulfillment in life beyond material wealth.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "Happiness comes from between. It comes from getting the right relationships between yourself and others, yourself and your work, and yourself and something larger than yourself."
          </Quote>
          <Quote>
            "The mind is divided into parts that sometimes conflict, like a rider trying to control an elephant."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "The Happiness Hypothesis" had a profound impact on how I think about happiness and well-being. One of the most striking ideas 
            in the book is the analogy of the divided mind—where our rational thoughts (the rider) often struggle to control our emotions 
            (the elephant). This concept resonated with me, as I’ve often felt the tension between knowing what’s best and actually acting 
            on it. It has helped me better understand why habits and emotional responses can be so difficult to change.
          </p>
          <br />
          <p>
            Haidt’s focus on the importance of relationships also left a lasting impression. The idea that happiness isn’t something we 
            achieve in isolation but rather "comes from between" ourselves and others aligns with my own experiences. It’s a reminder 
            that meaningful relationships and community play a huge role in our overall well-being.
          </p>
          <br />
          <p>
            Another takeaway was how ancient wisdom aligns with modern science, especially when it comes to mindfulness and meditation. 
            Haidt shows how these age-old practices, which might have seemed esoteric in the past, are now scientifically backed as ways 
            to improve mental health. This encouraged me to make mindfulness a more consistent part of my life, knowing that the benefits 
            go beyond just relaxation.
          </p>
          <br />
          <p>
            Ultimately, this book has inspired me to view happiness as a multifaceted, ongoing practice—one that requires attention not 
            only to our internal state but also to how we connect with the world around us.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default HappinessHypothesis;