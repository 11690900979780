import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9; /* Light gray background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #000000; /* Black for the bold title */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #333333; /* Dark gray for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555; /* Gray for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff; /* White background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #e60000; /* Red color for section titles */
  border-bottom: 2px solid #e60000;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark gray for content */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f0f0f0; /* Light gray for quotes */
  border-left: 4px solid #e60000;
  border-radius: 4px;
  color: #333333;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #e60000; /* Red for tags */
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #e60000;
    color: #fff;
  }
`;

export const HowToLieWithStatisticsMetadata = {
  title: "How to Lie with Statistics",
  description: "A classic introduction to the ways statistics can be manipulated.",
  tags: [
    'Statistics', 'Data Manipulation', 'Math', 'Algorithmic Bias',
    'Data Interpretation', 'Critical Thinking', 'Logic', 'Analysis'
  ],
  author: "Darrell Huff",
  datePublished: "2023-4-15",
  category: "Book Review",
  path: "/book-review/how-to-lie-with-statistics",
  coverImage: "/images/how_to_lie_with_statistics.jpg",
};

const HowToLieWithStatistics = () => {
  const metadata = HowToLieWithStatisticsMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "How to Lie with Statistics" is a classic introduction to the art of statistical manipulation.
            Darrell Huff provides a witty and accessible guide on how statistics are often misused to distort reality
            and influence public perception. The book explores various techniques and tricks used to present data in
            misleading ways.
          </p>
          <br />
          <p>
            Huff’s book remains relevant in today's world, where data and statistics are more prevalent than ever.
            Through humorous illustrations and real-world examples, the author shows how to critically evaluate
            information and spot manipulation tactics.
          </p>
          <br />
          <p>
            Whether you are a student, a business professional, or someone interested in data, "How to Lie with Statistics"
            offers essential lessons on becoming a more informed and skeptical consumer of information.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Data Manipulation:</strong> The various ways statistics can be distorted to mislead.</ListItem>
            <ListItem><strong>Critical Thinking:</strong> Techniques for critically evaluating statistical information.</ListItem>
            <ListItem><strong>Bias in Data:</strong> How bias can influence the interpretation of data.</ListItem>
            <ListItem><strong>Visual Deception:</strong> How charts and graphs can be used to mislead audiences.</ListItem>
            <ListItem><strong>Business and Economics:</strong> The use of statistics in business contexts to sway opinions or decisions.</ListItem>
            <ListItem><strong>Education on Data Literacy:</strong> The importance of understanding statistics and their limitations.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "There are three kinds of lies: lies, damned lies, and statistics."
          </Quote>
          <Quote>
            "A well-wrapped statistic is better than Hitler’s ‘big lie’; it misleads, yet it cannot be pinned on you."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "How to Lie with Statistics" made me realize just how easily data can be manipulated to present a certain narrative.
            In today’s data-driven world, it’s more important than ever to understand that statistics, while seemingly objective, 
            can be twisted to mislead and misinform.
          </p>
          <br />
          <p>
            As someone with a growing interest in data science, this book reminded me that data should be used ethically and transparently. 
            It’s not just about presenting numbers; it’s about ensuring that those numbers genuinely reflect reality and aren’t used to 
            deceive or manipulate.
          </p>
          <br />
          <p>
            What struck me the most were the real-world examples Huff used to show how visualizations, graphs, and carefully chosen 
            statistics can obscure the truth. This has made me more vigilant about how I consume data, and it reinforced the importance 
            of critical thinking in evaluating any statistical information presented to me.
          </p>
          <br />
          <p>
            This book has deepened my commitment to data literacy—not only in my own work but also in encouraging others to question and 
            critically evaluate the numbers they see in the media, advertising, and business reports.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default HowToLieWithStatistics;