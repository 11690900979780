// Dynamically import all metadata from the specified context
export const getAllMetadata = async (context) => {
  const modules = context.keys().map(context);

  // Extract metadata from each module by looking for the specific metadata key
  const metadata = modules.map((mod) => {
    // Attempt to find a key that ends with 'Metadata' (since all your metadata keys follow this pattern)
    const metadataKey = Object.keys(mod).find((key) => key.endsWith('Metadata'));

    if (metadataKey) {
      return mod[metadataKey];
    } else {
      return null;
    }
  });

  // Filter out any null values in case some modules did not have metadata
  const filteredMetadata = metadata.filter((item) => item !== null);
  return filteredMetadata;
};

// Function to fetch project metadata
export const fetchProjectMetadata = async () => {
  try {
    const projectContext = require.context('../projects', false, /\.js$/);
    const projectMetadata = await getAllMetadata(projectContext);
    return projectMetadata.sort(
      (a, b) => new Date(b.datePublished) - new Date(a.datePublished)
    );
  } catch (error) {
    console.error('Error fetching project metadata:', error);
  }
};

// Function to fetch blog metadata
export const fetchBlogMetadata = async () => {
  try {
    const blogContext = require.context('../blog', false, /\.js$/);
    const blogMetadata = await getAllMetadata(blogContext);
    return blogMetadata.sort(
      (a, b) => new Date(b.datePublished) - new Date(a.datePublished)
    );
  } catch (error) {
    console.error('Error fetching blog metadata:', error);
  }
};