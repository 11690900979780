import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffd45c; /* Yellow background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #000000; /* Black color */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #333333; /* Dark gray */
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555;
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #FFFFFF; /* White background */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #0077b5; /* Blue color */
  border-bottom: 2px solid #0077b5;
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark gray */
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #FFF8E1; /* Light yellow background */
  border-left: 4px solid #0077b5;
  border-radius: 4px;
  color: #666666;
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #0077b5; /* Blue background */
  color: #ffffff; /* White text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #0077b5;
    color: #fff;
  }
`;

export const ZeroToOneMetadata = {
  title: "Zero to One",
  description: "Notes on Startups, or How to Build the Future",
  tags: [
    'Entrepreneurship', 'Startups', 'Innovation', 'Business', 'Technology',
    'Strategy', 'Future', 'Growth', 'Venture Capital',
  ],
  author: "Peter Thiel with Blake Masters",
  datePublished: "2024-05-13",
  category: "Book Review",
  path: "/book-review/zero-to-one",
  coverImage: "/images/zero_to_one.jpg",
};

const ZeroToOne = () => {
  const metadata = ZeroToOneMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Zero to One" provides unique insights into the world of startups, offering unconventional advice on
            how to build successful companies that create new things rather than merely competing in existing markets.
            Peter Thiel discusses the importance of technology, innovation, and thinking outside the box when building
            a business, sharing his experiences and thoughts on how to achieve success.
          </p>
          <br />
          <p>
            The book emphasizes the power of going from zero to one—creating something fundamentally new and valuable,
            rather than simply making incremental improvements. Thiel’s perspective challenges common beliefs and provides
            thought-provoking insights for entrepreneurs and innovators.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Monopoly vs. Competition:</strong> Why building a monopoly is the goal of successful startups.</ListItem>
            <ListItem><strong>Future-Oriented Thinking:</strong> The importance of developing long-term visions for companies.</ListItem>
            <ListItem><strong>Technology and Innovation:</strong> How technology drives growth and innovation in business.</ListItem>
            <ListItem><strong>Vertical vs. Horizontal Progress:</strong> The concept of going from zero to one rather than one to n.</ListItem>
            <ListItem><strong>Thiel's Law:</strong> A startup must plan its strategy and operations from the outset, as mistakes in the early stages can be fatal.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "Every moment in business happens only once."
          </Quote>
          <Quote>
            "The best startups might be considered slightly less extreme kinds of cults."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "Zero to One" sparked some profound reflections on how innovation and success are approached in business.
            Thiel’s emphasis on creating something new—going from zero to one—really resonated with me. However, while I 
            agree with his idea that building a monopoly can be a critical element of a startup's success, I couldn't help 
            but recall the ideas from <a href="/book-review/where-good-ideas-come-from">"Where Good Ideas Come From"</a> by Steven Johnson.
          </p>
          <br />
          <p>
            Johnson talks about the concept of "the adjacent possible"—how innovation often happens by exploring 
            what's immediately around us, connecting ideas in ways that weren’t obvious before. This idea contrasts with 
            Thiel’s perspective of aiming for something entirely new, because often, new breakthroughs emerge through 
            serendipity and the combination of existing ideas. It’s not always about creating something completely original, 
            but rather about finding unexpected connections between seemingly unrelated things.
          </p>
          <br />
          <p>
            Thiel’s ideas around monopolies and planning early are certainly compelling, but I found myself questioning 
            whether the “zero to one” model always applies. Good ideas often arise organically when we are navigating the 
            “adjacent possible.” So, while Thiel advocates for bold leaps in innovation, Johnson’s concept reminds me that 
            progress often happens more gradually, through exploration and serendipitous discovery, rather than through 
            purely planned breakthroughs.
          </p>
          <br />
          <p>
            This balance between creating something entirely new and exploring what’s just beyond the current horizon is 
            where I think true innovation happens. Thiel’s advice is a great reminder to think big, but Johnson
            reminds us that serendipity and the evolution of ideas also play a huge role in shaping the next big thing.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default ZeroToOne;