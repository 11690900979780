import React from 'react';
import styled from 'styled-components';

const ProjectDetailContainer = styled.div`
  padding: 3rem 2rem;
  background-color: #f9f0f3; /* Light grey for the background */
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #260c05;
  margin-bottom: 0.5rem;
`;

const Date = styled.p`
  font-size: 0.9rem;
  color: #888; /* Neutral grey for dates */
`;

const TagsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: #ff1561; /* Pink for tags */
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Image = styled.img`
  display: block;
  max-width: 100%; /* Center the image */
  height: auto;
  margin: 0 auto 2rem auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: #ff1561; 
  margin-bottom: 1rem;
  border-bottom: 2px solid #ff1561;
  padding-bottom: 0.5rem;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #333; /* Dark text for readability */
  margin-bottom: 1rem;
`;

const List = styled.ul`
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #333;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem; /* Add spacing between each bullet point */
  line-height: 1.6;
`;

const Button = styled.button`
  display: flex;
  margin: 0 auto;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #ff1561; /* Red background for buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #a6132f; /* Darker red for hover state */
  }
`;


const SentimentAnalysis = () => {
  const tags = ['Data Cleaning', 'Natural Language Processing', 'Sentiment Analysis', 'Named Entity Recognition', 'Topic Modeling', 'Tourism'];
  const datePublished = '2024-10-07';

  return (
    <ProjectDetailContainer>
      <Header>
        <Title>Sentiment Dynamics and Topic Analysis of Greece’s Premier Summer Islands Destinations</Title>
        <Date>Published on: {datePublished}</Date>
      </Header>
      <Image src="/images/mentionlytics.jpg" alt="Sentiment Analysis" />
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>

      <br />
      <br />
      <br />

      <Section>
        <SectionTitle>Collaborators</SectionTitle>
        <List>
          <ListItem>
            <a href="https://www.linkedin.com/in/efrosini-pagkali/" target="_blank" rel="noopener noreferrer">Efrosini Pagkali</a>
          </ListItem>
          <ListItem>
            <a href="https://www.linkedin.com/in/konstantinos-lamprou-943038213/" target="_blank" rel="noopener noreferrer">Konstantinos Lamprou</a>
          </ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle >Overview</SectionTitle>
        <Text>
          Short-term consulting project in collaboration with{' '}
          <a href="https://www.mentionlytics.com/" target="_blank" rel="noopener noreferrer">
            Mentionlytics
          </a>{' '}
          and{' '}
          <a href="https://bigblue.academy/en" target="_blank" rel="noopener noreferrer">
            Big Blue Data Academy
          </a>. The project focused on analyzing social media posts associated with five major Greek tourism destinations:
        </Text>
        <List>
          <ListItem><strong>Santorini</strong></ListItem>
          <ListItem><strong>Mykonos</strong></ListItem> 
          <ListItem><strong>Rhodes</strong></ListItem> 
          <ListItem><strong>Crete</strong></ListItem> 
          <ListItem><strong>Corfu</strong></ListItem> 
        </List>
      </Section>
      
      <Section>
        <SectionTitle>Objectives</SectionTitle>
        <Text>
          The primary objectives of this project were to:
        </Text>
        <List>
          <ListItem>Analyze sentiment from social media posts about Greek tourism destinations.</ListItem>
          <ListItem>Identify dominant topics and trends using NLP techniques such as LDA (Latent Dirichlet Allocation).</ListItem>
          <ListItem>Provide insights on user behavior and preferences based on sentiment dynamics.</ListItem>
        </List>
      </Section>
      
      <Section>
        <SectionTitle>Use Cases</SectionTitle>
        <List>
          <ListItem><strong>Tourism Boards:</strong> Understanding public sentiment can help tourism boards identify areas where improvements can be made, such as overcrowding or environmental conservation.</ListItem>
          <ListItem><strong>Travel Agencies:</strong> Insights into which destinations are associated with positive or negative experiences can assist travel agencies in promoting destinations more effectively.</ListItem>
          <ListItem><strong>Local Businesses:</strong> Identifying trending topics, such as "local cuisine" or "luxury accommodations," helps local businesses tailor their offerings to tourist interests.</ListItem>
        </List>
      </Section>

      <Section>
      <SectionTitle>Tools Used</SectionTitle>
      <Text>
        A variety of tools and libraries were employed throughout the project to process, analyze, and visualize the data. These tools helped ensure efficient data handling, accurate sentiment analysis, and insightful visualizations. Below is a detailed overview of the key tools and their roles in the project:
      </Text>
      <List>
      
        <ListItem>
          <strong>Mentionlytics:</strong> We used the platform’s built-in tools, such as the word cloud, to efficiently identify key trends and exclude irrelevant keywords, providing a quick overview of the data before conducting more detailed analysis.
        </ListItem>

        <ListItem>
          <strong>Python:</strong> Python served as the primary scripting language due to its flexibility, extensive libraries, and ease of integration with various data science tools.
        </ListItem>

        <ListItem>
          <strong>Pandas and NumPy:</strong> These two libraries were fundamental in handling and manipulating the data throughout the project.
        </ListItem>

        <ListItem>
          <strong>Matplotlib and Seaborn:</strong> These visualization libraries were used to create insightful graphs and charts that helped us communicate the results of the analysis. 
        </ListItem>

        <ListItem>
          <strong>Google Cloud Translate API:</strong> This API was used to automatically translate non-English social media posts into English.
        </ListItem>

        <ListItem>
          <strong>spaCy:</strong> We utilized spaCy to perform Named Entity Recognition (NER).
        </ListItem>

        <ListItem>
          <strong>Gensim:</strong> Gensim was used to implement Latent Dirichlet Allocation (LDA) to uncover the dominant topics discussed in the dataset.
        </ListItem>

        <ListItem>
          <strong>VADER:</strong> VADER (Valence Aware Dictionary and sEntiment Reasoner) was employed for sentiment analysis.
        </ListItem>

        <ListItem>
          <strong>EmoLex:</strong> EmoLex, the NRC Emotion Lexicon, was used to identify the emotions associated with the posts. 
        </ListItem>

      
      </List>
    </Section>

      <Section>
        <SectionTitle>Methodology</SectionTitle>
        <Text>
          Our research methodology involved leveraging data from the Mentionlytics platform, a powerful social media monitoring tool that provides real-time insights into online conversations. The overall process included several key steps aimed at extracting, cleaning, and analyzing the data to produce meaningful insights. The steps are outlined below:
        </Text>
        <List>
          <ListItem><strong>Data Collection: </strong> 
            We initiated the process by extracting a comprehensive set of social media posts from the Mentionlytics platform. This data was collected using pre-defined keywords related to our research focus. Mentionlytics enables real-time data extraction, giving us access to a diverse range of posts across platforms, including Twitter, Facebook, Instagram, and blogs. This provided a rich dataset for analysis, covering various contexts and discussions.
          </ListItem>

          <ListItem><strong>Element Extraction: </strong> 
            Before diving into the cleaning process, we focused on extracting key elements from the raw text data that might provide useful insights later on. These included hashtags, mentions of users, URLs, and emojis. The goal here was to preserve these elements because they could add value to sentiment and topic analysis, but they might have been lost during the cleaning phase. For instance, emojis often convey emotion and context, while hashtags can indicate the themes or topics of discussion.
          </ListItem>

          <ListItem><strong>Data Cleaning: </strong> 
            The cleaning process involved several steps to prepare the text for analysis. First, we removed remaining special characters, numbers, and irrelevant symbols that did not contribute to the text meaning. We then normalized the unicode text to standardize the format across posts written in different languages or with different character encodings. The most time-consuming part of this step was applying keyword filtering to eliminate irrelevant topics and posts. This required extensive exploratory data analysis (EDA) to identify the correct keywords for exclusion. We had to carefully refine the filters to avoid mistakenly removing posts that were actually relevant, which was particularly challenging in a dataset spanning multiple languages and contexts.
          </ListItem>

          <ListItem><strong>Content Translation: </strong> 
            Given the multilingual nature of the data, we utilized Google's Cloud Translation API to translate all non-English content into English. This step was crucial for ensuring consistency in the analysis, as sentiment analysis and topic modeling tools typically perform better on English text. The translation process allowed us to work with a unified corpus while preserving the original meaning of the posts as accurately as possible.
          </ListItem>

          <ListItem><strong>Aspect-Based Sentiment Analysis: </strong> 
            After preparing the text, we performed aspect-based sentiment analysis using a combination of VADER and spaCy's Named Entity Recognition (NER). VADER, a rule-based tool designed for social media sentiment analysis, provided sentiment scores for each post. Meanwhile, spaCy's NER was used to identify key entities in the text, such as locations, organizations, and people. This allowed us to perform a more granular analysis by associating specific sentiments with the entities mentioned in the posts. Finally, we employed Latent Dirichlet Allocation (LDA) to conduct topic modeling, which helped us identify the dominant themes in the dataset. Each post was assigned a topic, and we explored the sentiment trends within these topics to understand how different themes were perceived by the audience.
          </ListItem>
        </List>
      </Section>

      <Section>
      <SectionTitle>Results</SectionTitle>
      <Text>
        The analysis of social media posts revealed several valuable insights into the sentiments expressed by users across different aspects of their experiences in Greece’s premier summer destinations:
      </Text>
      <List>
        <ListItem>
          <strong>Consistently Positive Sentiment for Food and Adventure: </strong> 
          Social media users expressed overwhelmingly positive sentiment toward local food and adventure experiences. Posts highlighted a strong appreciation for traditional Greek cuisine, such as seafood and local delicacies, with words like ‘delicious’ and ‘authentic’ commonly associated with food. Adventure activities, including boat tours, hiking, and water sports, were also praised for their thrilling experiences and scenic views. These findings suggest that Greece’s rich culinary traditions, combined with its stunning natural landscapes, offer visitors an exceptional blend of cultural and outdoor adventures, making the country a top destination for those seeking both beauty and excitement.
        </ListItem>

        <ListItem>
          <strong>Neutral Sentiment for Accommodation and Weather: </strong> 
          Posts about accommodation and weather exhibited more neutral sentiment, with experiences varying based on individual circumstances. While some travelers shared positive reviews about luxury accommodations and comfortable stays, others voiced concerns about subpar service or unexpected issues like cleanliness. Similarly, weather-related posts were mixed, with some visitors praising the sunny Mediterranean climate, while others expressed frustration with unexpected rain or extreme heat. These factors, which often depend on timing and personal expectations, led to more balanced sentiment overall.
        </ListItem>

        <ListItem>
          <strong>Consistently Negative Sentiment for Transportation: </strong> 
          Transportation, particularly air travel, emerged as the most significant concern for users. There was a notable increase in negative sentiment toward airports, frequently described as overcrowded and plagued by delays. Complaints primarily focused on long wait times and inefficient services. Public transportation, including buses and ferries, also faced criticism for being unreliable and overcrowded, especially during peak tourist seasons. Given that the data was collected from posts made in August—Greece’s busiest tourist month—these issues are understandable. However, they highlight a crucial area for improvement, as efficient transportation plays a key role in shaping positive travel experiences.
        </ListItem>
      </List>
      </Section>

      <Section>
        <SectionTitle>Presentation</SectionTitle>
        <Text>
          You can watch our presentation below. Note that although the slides are in English, the presentation is in Greek.
        </Text>
        <div style={{ margin: '2.5rem 0', display: 'flex', justifyContent: 'center' }}>
          <iframe width="809" height="520" src="https://www.youtube.com/embed/b9suQJhGrlg?si=4Gzygnb16NpMXVdI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      </Section>

      <Section>
        <SectionTitle>Conclusion</SectionTitle>
        <Text>
        The project yielded valuable insights into the sentiments and preferences of users meantioning Greece’s top summer destinations. By analyzing social media posts, we identified key areas where improvements could be made, such as transportation, while also highlighting the positive perceptions of local food and adventure activities. These findings provide actionable insights for tourism boards and businesses to enhance visitor experiences and tailor their offerings to better meet the expectations and preferences of travelers.
        </Text>
      </Section>

      <Button onClick={() => window.history.back()}>Back to Projects</Button>
    </ProjectDetailContainer>
  );
};

// Export metadata for use in the Projects page
export const sentimentAnalysisMetadata = {
  title: 'Sentiment Dynamics and Topic Analysis',
  description: 'An analysis of social media posts related to Greek tourism destinations using NLP techniques.',
  tags: ['Data Cleaning', 'Natural Language Processing', 'Sentiment Analysis', 'Named Entity Recognition', 'Topic Modeling', 'Tourism'],
  languages: ['Python', 'PowerBI'],
  datePublished: '2024-10-07',
  path: '/projects/mentionlytics-sentiment',
  coverImage: 'images/mentionlytics.jpg'
};

export default SentimentAnalysis;