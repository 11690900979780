import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f5f7; /* Light gray background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: #ff4242; /* Red background matching the book cover */
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #ffffff; /* White for the bold title */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #ffff66; /* Yellow for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #ffffff; /* White for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff; /* White background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #ff1a1a; /* Red for section titles */
  border-bottom: 2px solid #ff1a1a;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark gray for content */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #fff7e6; /* Light yellow for quotes */
  border-left: 4px solid #ffcc00;
  border-radius: 4px;
  color: #333333;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #ffcc00; /* Yellow for tags */
  color: black;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #ffcc00;
    color: #000;
  }
`;

export const TheSixthExtinctionMetadata = {
  title: "The Sixth Extinction",
  description: "An exploration of how human activity is driving mass extinction.",
  tags: [
    'Extinction', 'Environment', 'Ecology', 'Climate Change', 'Sustainability',
    'Biology', 'Conservation', 'History', 'Biodiversity', 'Evolution'
  ],
  author: "Elizabeth Kolbert",
  datePublished: "2023-08-05",
  category: "Book Review",
  path: "/book-review/the-sixth-extinction",
  coverImage: "/images/sixth_extinction.jpg", // Ensure this path is correct
};

const TheSixthExtinction = () => {
  const metadata = TheSixthExtinctionMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "The Sixth Extinction" is a Pulitzer Prize-winning book by Elizabeth Kolbert that explores the concept of mass extinction
            and the human activities driving it. Kolbert draws on her extensive research and travels to provide a comprehensive
            and engaging account of how humanity's actions are accelerating the loss of species at an alarming rate.
          </p>
          <br />
          <p>
            The book combines scientific insight with storytelling as Kolbert documents the decline of various species across the globe.
            From amphibians to coral reefs, she discusses the impact of climate change, deforestation, and pollution on ecosystems.
          </p>
          <br />
          <p>
            Kolbert’s work highlights the urgent need for conservation and changes in policy to combat the ongoing environmental crisis.
            It serves as both a warning and a call to action, emphasizing the need to address the root causes of biodiversity loss.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Mass Extinction:</strong> The unprecedented rate of species loss caused by human activity.</ListItem>
            <ListItem><strong>Impact of Climate Change:</strong> How rising temperatures and changing weather patterns affect ecosystems.</ListItem>
            <ListItem><strong>Ecological Consequences:</strong> The effects of deforestation, pollution, and habitat destruction on biodiversity.</ListItem>
            <ListItem><strong>Evolutionary Biology:</strong> Insights into how species adapt—or fail to adapt—to environmental changes.</ListItem>
            <ListItem><strong>Conservation Efforts:</strong> The importance of protecting endangered species and habitats.</ListItem>
            <ListItem><strong>Human Responsibility:</strong> The role of humanity in reversing or exacerbating the current ecological crisis.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "Right now, in the astonishingly brief period since humans appeared on earth, we are seeing a massive spasm of extinction."
          </Quote>
          <Quote>
            "In pushing other species to extinction, humanity is busy sawing off the limb on which it perches."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "The Sixth Extinction" was both eye-opening and unsettling. The urgency with which Elizabeth Kolbert writes about 
            the current ecological crisis really hit home for me. It made me reflect on how much human activity, from deforestation 
            to pollution, is rapidly accelerating the extinction of species across the globe.
          </p>
          <br />
          <p>
            One of the key takeaways for me was the sheer scale of the impact that climate change and human intervention are having 
            on biodiversity. While the statistics and facts were alarming, it was Kolbert’s storytelling that made the crisis feel 
            personal—seeing how individual species are being wiped out due to factors largely within our control.
          </p>
          <br />
          <p>
            This book reinforced my growing awareness of the role we all play in this crisis. It’s a stark reminder that our actions 
            have long-term consequences for the planet and for future generations. Kolbert’s exploration of extinction is not just 
            about species loss but about the fragility of the ecosystems that sustain all life—including human life.
          </p>
          <br />
          <p>
            As I think about how to act on the knowledge from this book, it reminds me that conservation efforts and environmental 
            policies are not just about preserving individual species—they're about protecting the intricate web of life that makes 
            the Earth habitable. This book has given me a new sense of responsibility toward the environment and a motivation to 
            be more mindful in my daily choices.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default TheSixthExtinction;