import React, { useEffect, useState, lazy, Suspense, useCallback, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  FaLinkedin,
  FaGithub,
  FaPython,
  FaDatabase,
  FaFileExcel,
  FaCogs,
  FaChartBar,
} from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { FaCartShopping } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { fetchProjectMetadata, fetchBlogMetadata } from './utils/getMetadata';
import ScrollToTopButton from './utils/ScrollToTopButton';
import { Typewriter } from 'react-simple-typewriter';

const WordCloud = lazy(() => import('react-wordcloud'));

// Keyframes for the bounce animation
const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

// Styled components
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 100vh;
  padding: 2rem;
  background: url('/images/waves.svg') no-repeat bottom center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40vh;
  text-align: center;
  width: 100%;

  @media (max-width: 1024px) {
    margin-bottom: 50vh;
  }
  @media (max-width: 768px) {
    margin-bottom: 50vh;
  }

  @media (max-width: 480px) {
    margin-bottom: 45vh;
  }
`;

const StyledTitle = styled.h1`
  font-size: 4vw;
  margin-bottom: 0.5rem;
  color: white;
  text-align: center;
  padding: 2rem 0.5rem;
  border-bottom: 2px solid #fff;

  @media (max-width: 1024px) {
    font-size: 6vw;

  @media (max-width: 768px) {
    font-size: 6vw;
  }

  @media (max-width: 480px) {
    font-size: 7vw;
  }
`;

const MotionTitle = motion.create(StyledTitle);

const StyledDynamicHeadline = styled.h2`
  font-size: 1.5rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;

const MotionDynamicHeadline = motion.create(StyledDynamicHeadline);

const StaticText = styled.span`
  margin-right: 0.5rem;
  font-size: 2rem;
  line-height: 1.2;

  @media (max-width: 1024px) {
    font-size: 3vh;
    line-height: 1.4;
  }
  @media (max-width: 768px) {
    font-size: 2.5vh;
    line-height: 1.4;
  }

  @media (max-width: 480px) {
    font-size: 2.5vh;
    line-height: 1.4;
  }
`;

const RotatingKeywords = styled.span`
  color: #ffc107;
  font-size: 2rem;
  line-height: 1.2;

  @media (max-width: 1024px) {
    font-size: 3vh;
    line-height: 1.4;
  }
  @media (max-width: 768px) {
    font-size: 2.5vh;
    line-height: 1.4;
  }

  @media (max-width: 480px) {
    font-size: 2.5vh;
    line-height: 1.4;
  }
`;

const SocialLinks = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
`;

const IconLink = styled.a`
  font-size: 2rem;
  color: white;
  transition: color 0.2s;

  &:hover {
    color: #6c757d;
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 3rem 2rem;
  box-sizing: border-box;
  background-color: ${({ bgcolor }) => bgcolor || '#f5f5f5'};

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 1rem 0.5rem;
  }
`;

const Section = motion.create(StyledSection);

const SectionTitleW = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 0.5rem;
  width: fit-content;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const SectionTitleB = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #555;
  border-bottom: 2px solid #555;
  padding-bottom: 0.5rem;
  width: fit-content;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 320px;
  cursor: pointer;
  transition: transform 0.2s;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 280px;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    height: 150px;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #333;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const CardDivider = styled.hr`
  width: 80%;
  margin: 1rem auto;
  border: 0;
  border-top: 2px solid #eee;
`;

const CardDescription = styled.p`
  font-size: 0.95rem;
  color: #666;
  line-height: 1.4;
  text-align: center;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const StaticCard = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 320px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  transition: none;

  @media (max-width: 768px) {
    width: 280px;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const ExploreButton = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 480px) {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
`;

const WordCloudWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 600px;
  }

  @media (max-width: 480px) {
    height: 700px;
    width: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 165px; /* Adjust this based on your design */
  width: 100%;

  @media (max-width: 1024px) {
    bottom: 250px;
  @media (max-width: 768px) {
    bottom: 180px;

  @media (max-width: 480px) {
    bottom: 170px;
  }
`;

const BouncingIcon = styled(FiChevronDown)`
  font-size: 2rem;
  color: white;
  animation: ${bounceAnimation} 1s ease-in-out infinite;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const InterestCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-width: 1200px;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const CtaButton = styled.button`
  padding: 1rem 1.75rem;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  background-color: #fff;
  color: #323232;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1;

  &:hover {
    background-color: #6c757d;
    color: #fff;
    transform: scale(1.075);
  }

  @media (max-width: 480px) {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
`;

const keywords = [
  'Business Intelligence',
  'Data Analytics',
  'Process Optimization',
  'Data Science',
  'Machine Learning',
  'Automation',
  'Technology',
];

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1, ease: 'easeOut' } },
};

const Home = () => {
  const [projects, setProjects] = useState([]);
  const [opinions, setOpinions] = useState([]);
  const [tags, setTags] = useState([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [showTypewriter, setShowTypewriter] = useState(false); // Typewriter delay control
  const [isWordCloudVisible, setIsWordCloudVisible] = useState(false); // WordCloud visibility
  const [showBouncingIcon, setShowBouncingIcon] = useState(false); // Bouncing icon control
  const navigate = useNavigate();

  // Typewriter delay fix
  useEffect(() => {
    const delayTypewriter = setTimeout(() => setShowTypewriter(true), 500);
    return () => clearTimeout(delayTypewriter);
  }, []);

  // Delay the appearance of the Bouncing Icon by 3 seconds
  useEffect(() => {
    const iconDelay = setTimeout(() => setShowBouncingIcon(true), 3000); // 3 seconds
    return () => clearTimeout(iconDelay);
  }, []);

  // Intersection Observer for WordCloud
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsWordCloudVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const wordCloudSection = document.getElementById('word-cloud-section');
    if (wordCloudSection) observer.observe(wordCloudSection);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const [projectData, blogData] = await Promise.all([
        fetchProjectMetadata(),
        fetchBlogMetadata(),
      ]);

      setProjects(projectData.slice(0, 3));
      setOpinions(blogData.slice(0, 3));

      const allTags = [...projectData, ...blogData].flatMap((item) => item.tags || []);

      const tagCounts = allTags.reduce((acc, tag) => {
        acc[tag] = (acc[tag] || 0) + 1;
        return acc;
      }, {});

      const wordCloudData = Object.entries(tagCounts).map(([text, value]) => ({
        text,
        value,
      }));

      setTags(wordCloudData);
    };

    fetchData();

    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCardClick = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  const handleExploreClick = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  const wordCloudOptions = useMemo(
    () => ({
      rotations: 2,
      rotationAngles: [-45, 0],
      scale: 'sqrt',
      spiral: 'archimedean',
      fontSizes: [20, 80],
      padding: 3,
      fontWeight: 'bold',
      deterministic: false,
    }),
    []
  );

  const wordCloudCallbacks = useMemo(
    () => ({
      onWordClick: (word) => {
        console.log(`You clicked on ${word.text}`);
      },
      getWordTooltip: (word) => `${word.text} (occurrences: ${word.value})`,
    }),
    []
  );

  return (
    <>
      <HomeContainer>
        <TitleContainer>
          <MotionTitle initial="hidden" animate="visible" variants={fadeVariants} style={{ letterSpacing: '0.05rem' }}>
            Kyriakos Papadopoulos
          </MotionTitle>
          <br />
          <MotionDynamicHeadline initial="hidden" animate="visible" variants={fadeVariants}>
            <StaticText style={{ letterSpacing: '0.05rem' }}>
              Enhancing strategic decisions <br></br> with
            </StaticText>
            <RotatingKeywords>
              {showTypewriter ? (
                <Typewriter
                  words={keywords}
                  loop={1}
                  cursor
                  cursorStyle=""
                  typeSpeed={80}
                  deleteSpeed={40}
                  delaySpeed={400}
                />
              ) : (
                <span></span>
              )}
            </RotatingKeywords>
          </MotionDynamicHeadline>
          <SocialLinks>
            <IconLink href="https://www.linkedin.com/in/papadopoulos-kyriakos/" target="_blank" aria-label="LinkedIn Profile">
              <FaLinkedin />
            </IconLink>
            <IconLink href="https://github.com/kyriakos-papadopoulos/Projects/tree/main" target="_blank" aria-label="GitHub Profile">
              <FaGithub />
            </IconLink>
          </SocialLinks>
          <br />
          <CtaButton onClick={() => handleExploreClick('/about')}>
            Discover my Journey
          </CtaButton>
          {showBouncingIcon && (
            <IconContainer>
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <BouncingIcon />
              </motion.div>
            </IconContainer>
          )}
        </TitleContainer>
      </HomeContainer>

      {/* Areas of Interest Section */}
      <Section variants={fadeVariants} bgcolor="#828282">
        <SectionTitleW>Areas of Interest</SectionTitleW>
        <InterestCardContainer>
          <StaticCard>
            <FaFileExcel size={50} style={{ marginBottom: '1rem' }} />
            <CardTitle>Excel</CardTitle>
            <CardDivider />
            <CardDescription>
              Proficient in Excel for data analysis, financial modeling, and business reporting.
            </CardDescription>
          </StaticCard>
          <StaticCard>
            <FaDatabase size={50} style={{ marginBottom: '1rem' }} />
            <CardTitle>SQL</CardTitle>
            <CardDivider />
            <CardDescription>
              Skilled in database management and querying, leveraging SQL for analytics and insights.
            </CardDescription>
          </StaticCard>
          <StaticCard>
            <FaPython size={50} style={{ marginBottom: '1rem' }} />
            <CardTitle>Python</CardTitle>
            <CardDivider />
            <CardDescription>
              Python is my go-to for data analysis, automation, and machine learning applications.
            </CardDescription>
          </StaticCard>
          <StaticCard>
            <FaChartBar size={50} style={{ marginBottom: '1rem' }} />
            <CardTitle>Data Analytics</CardTitle>
            <CardDivider />
            <CardDescription>
              I excel at turning complex data into compelling stories through analysis and presentation.
            </CardDescription>
          </StaticCard>
          <StaticCard>
            <FaCogs size={50} style={{ marginBottom: '1rem' }} />
            <CardTitle>Machine Learning</CardTitle>
            <CardDivider />
            <CardDescription>
              Skilled in applying machine learning and eager to deepen my understanding of its theory.
            </CardDescription>
          </StaticCard>
          <StaticCard>
            <FaCartShopping size={50} style={{ marginBottom: '1rem' }} />
            <CardTitle>WooCommerce</CardTitle>
            <CardDivider />
            <CardDescription>
              Experienced in managing WooCommerce platforms, optimizing sales, and enhancing user experience.
            </CardDescription>
          </StaticCard>
        </InterestCardContainer>
      </Section>

      {/* Projects Section */}
      <Section initial="hidden" whileInView="visible" variants={fadeVariants} bgcolor="#fafafa">
        <SectionTitleB>My Latest Projects</SectionTitleB>
        <CardContainer>
          {projects.map((project) => (
            <Card key={project.path} onClick={() => handleCardClick(project.path)}>
              <CardImage
                src={project.coverImage}
                alt={project.title}
                loading="lazy"
                width="320"
                height="200"
              />
              <CardTitle>{project.title}</CardTitle>
              <CardDivider />
              <CardDescription>{project.description}</CardDescription>
            </Card>
          ))}
        </CardContainer>
        <br />
        <ExploreButton onClick={() => handleExploreClick('/projects')}>
          Explore All Projects
        </ExploreButton>
      </Section>

      {/* Blog Section */}
      <Section initial="hidden" whileInView="visible" variants={fadeVariants} bgcolor="#828282">
        <SectionTitleW>Blog</SectionTitleW>
        <CardContainer>
          {opinions.map((opinion) => (
            <Card key={opinion.path} onClick={() => handleCardClick(opinion.path)}>
              <CardImage
                src={opinion.coverImage}
                alt={opinion.title}
                loading="lazy"
                width="320"
                height="200"
              />
              <CardTitle>{opinion.title}</CardTitle>
              <CardDivider />
              <CardDescription>{opinion.description}</CardDescription>
            </Card>
          ))}
        </CardContainer>
        <br />
        <ExploreButton onClick={() => handleExploreClick('/blog')}>
          Explore All Opinions
        </ExploreButton>
      </Section>

      {/* At a Glance Section */}
      <Section id="word-cloud-section" initial="hidden" whileInView="visible" variants={fadeVariants} bgcolor="#fafafa">
        <SectionTitleB>At a Glance</SectionTitleB>
        <WordCloudWrapper>
          {isWordCloudVisible && (
            <Suspense fallback={<div>Loading...</div>}>
              <WordCloud
                words={tags}
                options={wordCloudOptions}
                callbacks={wordCloudCallbacks}
              />
            </Suspense>
          )}
        </WordCloudWrapper>
      </Section>

      {isDesktop && <ScrollToTopButton />}
    </>
  );
};

export default React.memo(Home);