// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";  // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2mpbBhBauDqeebjOVNnnMnkBzhZi1YJE",
  authDomain: "portfolio-8fb07.firebaseapp.com",
  projectId: "portfolio-8fb07",
  storageBucket: "portfolio-8fb07.appspot.com",
  messagingSenderId: "64611768501",
  appId: "1:64611768501:web:1eb3529d6d055d16e9c6fc",
  measurementId: "G-4Z3TFRHXX1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app); 

// Export the initialized Firebase app, analytics, and Firestore db
export { app, analytics, db };