import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121212; /* Black background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #00ff00; /* Bright green to match the cover */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #b2b2b2; /* Light gray for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #b2b2b2; /* Light gray for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #1a1a1a; /* Dark gray background for the content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #00ff00; /* Bright green */
  border-bottom: 2px solid #00ff00;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #e0e0e0; /* Light gray for text */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #0d0d0d; /* Very dark gray for quote background */
  border-left: 4px solid #00ff00;
  border-radius: 4px;
  color: #b2b2b2; /* Light gray for quote text */
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #e0e0e0;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #00ff00;
  color: black;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #00ff00;
    color: #000;
  }
`;

export const MindfckMetadata = {
  title: "Mindf*ck",
  description: "Cambridge Analytica and the Plot to Break America",
  tags: [
    'Data', 'Politics', 'Privacy', 'Surveillance', 'Psychology', 'Social Media',
    'Disinformation', 'Democracy', 'Data science', 'Artificial Intelligence', 'Ethics'
  ],
  author: "Christopher Wylie",
  datePublished: "2023-10-13",
  category: "Book Review",
  path: "/book-review/mindfck",
  coverImage: "/images/mindfck.jpg", // Ensure this path is correct
};

const Mindfck = () => {
  const metadata = MindfckMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Mindf*ck" unveils the dark side of data and the manipulation of democracy through technology.
            Christopher Wylie, a former Cambridge Analytica insider, details how the firm exploited social media,
            psychology, and data science to influence elections and disrupt democratic processes worldwide.
          </p>
          <br />
          <p>
            Wylie explains the mechanics behind Cambridge Analytica's strategies, including profiling and micro-targeting
            individuals, and how these techniques were used to manipulate public opinion. He connects the dots between
            the technology industry's vast reach, unethical practices, and the threat these pose to privacy and democracy.
          </p>
          <br />
          <p>
            The book also delves into the broader implications of how data is being weaponized, calling for a deeper examination
            of the relationship between technology, power, and society. Wylie's account serves as both a warning and a call to action
            for greater transparency and ethical considerations in the digital age.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Data Exploitation:</strong> The use of personal data to manipulate and influence public opinion on a massive scale.</ListItem>
            <ListItem><strong>Cambridge Analytica's Tactics:</strong> Insights into the psychological and technological methods used to sway elections.</ListItem>
            <ListItem><strong>Impact on Democracy:</strong> How digital tools and disinformation campaigns threaten democratic processes.</ListItem>
            <ListItem><strong>Privacy Concerns:</strong> The implications of data collection and surveillance on individual rights.</ListItem>
            <ListItem><strong>Manipulation of Social Media:</strong> The role of social media platforms in the spread of disinformation and propaganda.</ListItem>
            <ListItem><strong>Technology and Power:</strong> The relationship between tech giants, governments, and the misuse of data.</ListItem>
            <ListItem><strong>Call for Ethical Reform:</strong> Wylie’s plea for accountability and ethical standards in technology and data usage.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "We used data to create a weapon, and that weapon was pointed at democracy."
          </Quote>
          <Quote>
            "If you’re not paying for the product, you are the product."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "Mindf*ck" was a real eye-opener for me. The book exposed just how far social media companies are willing to go 
            to collect data and increase engagement. Christopher Wylie’s account of how Cambridge Analytica exploited these platforms
            made me realize the lengths to which politicians will go to leverage this data against us, manipulating opinions 
            and even election outcomes.
          </p>
          <br />
          <p>
            Before reading this book, I was aware that data was valuable, but I didn’t fully grasp how deeply entrenched data 
            exploitation is in the way our digital systems function. The idea that our social media activity—likes, shares, 
            comments—could be used to profile and target us with messages designed to manipulate our beliefs is disturbing, 
            yet very real. 
          </p>
          <br />
          <p>
            What stood out the most to me was the sheer lack of transparency around these practices. The fact that most of us 
            engage with these platforms without understanding how we’re being used as products is alarming. Wylie’s story 
            showed me the importance of being more mindful about how we interact online and the need for greater regulation 
            of how data is used.
          </p>
          <br />
          <p>
            This book has heightened my awareness of the potential for misuse in the tech and political arenas. It’s a reminder 
            that we need to be more vigilant as users and push for stronger ethical standards in the industry, especially when 
            it comes to safeguarding democracy.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default Mindfck;