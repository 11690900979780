import React from 'react';
import styled from 'styled-components';

const LibrariesPageContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2f6ad7;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.2;
`;

const DatePublished = styled.p`
  font-size: 1.1rem;
  color: #555555;
  margin-bottom: 2rem;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2.5rem;
  background-color: #f9f9f9;
  padding: 2rem 2.5rem;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.9rem;
  margin-bottom: 1.5rem;
  color: #2f6ad7;
  border-bottom: 3px solid #2f6ad7;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333333;
  margin-bottom: 1.5rem;
`;

const LibraryItem = styled.p`
  margin-bottom: 1.5rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #2f6ad7;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-size: 0.9rem;
`;

const Button = styled.button`
  margin-top: 2.5rem;
  padding: 0.8rem 2.5rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: #2f6ad7;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #000;
  }
`;

export const FavoriteLibrariesMetadata = {
  title: "Favorite Data Science Libraries",
  description: "A rundown of my favorite Python libraries for data science and why I use them.",
  tags: [
    'Python', 'Data Science', 'Libraries', 'Visualization', 'Machine Learning',
    'NLP', 'APIs', 'Automation', 'Neural Networks'
  ],
  datePublished: "2024-07-01",
  category: "Article",
  path: "/article/favorite-data-science-libraries",
  coverImage: "/images/python.png", 
};

const FavoriteDataScienceLibraries = () => {
  const metadata = FavoriteLibrariesMetadata;

  return (
    <LibrariesPageContainer>
      <Title>{metadata.title}</Title>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>

      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />

      <ContentSection>
        <SectionTitle>Introduction</SectionTitle>
        <SectionContent>
          <LibraryItem>
            Over the past couple years, I've worked with a variety of Python libraries that have significantly improved my workflow in data science, machine learning, and automation. These libraries not only offer essential tools for data manipulation and visualization but also provide advanced functionality in machine learning and natural language processing. Below is a detailed rundown of some of my favorite libraries, each serving a unique purpose in my data science toolkit.
          </LibraryItem>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Data Manipulation and Analysis</SectionTitle>
        <SectionContent>
          <LibraryItem><strong>Pandas:</strong> Pandas excels at making data manipulation easy. With its intuitive DataFrame structure, I can clean, filter, and merge data effortlessly. The wide variety of methods and built-in functions allow me to perform complex operations in just a few lines of code.</LibraryItem>
          <LibraryItem><strong>NumPy:</strong> NumPy’s powerful array operations are essential for efficient numerical computation. It offers advanced capabilities for performing element-wise operations, matrix multiplications, and handling multidimensional data efficiently.</LibraryItem>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Data Visualization</SectionTitle>
        <SectionContent>
          <LibraryItem><strong>Matplotlib:</strong> The foundation of most Python visualizations, Matplotlib provides granular control over charts. Whether it’s bar plots, line charts, or scatter plots, I rely on Matplotlib’s flexibility to create both static and interactive visualizations.</LibraryItem>
          <LibraryItem><strong>Seaborn:</strong> Built on Matplotlib, Seaborn simplifies the creation of beautiful statistical graphics. Its ability to handle complex datasets with minimal code is unmatched. I particularly enjoy using its heatmaps and pairplots for exploratory data analysis.</LibraryItem>
          <LibraryItem><strong>Plotly Express:</strong> Plotly takes visualizations to the next level with interactive, web-based charts. Plotly Express is perfect for rapidly generating complex, interactive plots with minimal effort, making it a favorite for dashboards and presentations.</LibraryItem>
          <LibraryItem><strong>Dash:</strong> Dash is a game-changer for building web applications in Python. With Dash, I can create fully interactive dashboards that integrate with my data analysis workflows, bringing my insights to life for any audience.</LibraryItem>
          <LibraryItem><strong>LDAvis:</strong> For visualizing topic models, especially when using Gensim’s LDA, LDAvis is incredibly useful. It helps me visually explore the relationships between topics, offering clarity on complex natural language processing results.</LibraryItem>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Machine Learning</SectionTitle>
        <SectionContent>
          <LibraryItem><strong>Scikit-learn:</strong> Scikit-learn is the backbone of my machine learning workflow. Its wide range of algorithms, from classification to clustering, makes it indispensable for any project. I frequently use its preprocessing tools, like <em>StandardScaler</em>, and its powerful model evaluation techniques, such as cross-validation, ensure the robustness of my models.</LibraryItem>
          <LibraryItem><strong>TensorFlow & Keras:</strong> When it comes to deep learning, TensorFlow and Keras are my go-to libraries. They allow me to build and train neural networks with ease, whether it's for simple image classification tasks or more complex architectures for NLP. Keras’s high-level API makes it intuitive to develop and experiment with models.</LibraryItem>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Natural Language Processing (NLP)</SectionTitle>
        <SectionContent>
          <LibraryItem><strong>Regex (Regular Expressions):</strong> I frequently use regular expressions for text preprocessing, cleaning, and pattern matching. Whether it's extracting specific information from large text corpora or transforming text data, the <em>re</em> library provides powerful pattern recognition tools essential in my NLP workflows.</LibraryItem>
          <LibraryItem><strong>SpaCy:</strong> SpaCy offers fast, efficient NLP pipelines for large-scale data processing. Its pre-trained models for tokenization, part-of-speech tagging, and Named Entity Recognition (NER) allow me to process text data rapidly, making it ideal for production-level NLP tasks.</LibraryItem>
          <LibraryItem><strong>Gensim:</strong> Gensim’s focus on topic modeling is unparalleled, particularly when working with Latent Dirichlet Allocation (LDA). Its ability to handle large corpora and its integration with LDAvis makes it invaluable for extracting meaningful insights from textual data.</LibraryItem>
          <LibraryItem><strong>NLTK:</strong> The Natural Language Toolkit (NLTK) is fundamental for building complex NLP pipelines, especially when working with tokenization, parsing, and more granular text processing. Its comprehensive set of tools makes it perfect for educational projects and more customized NLP tasks.</LibraryItem>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>APIs and Automation</SectionTitle>
        <SectionContent>
          <LibraryItem><strong>Requests:</strong> Whenever I need to send HTTP requests or interact with web APIs, Requests is my go-to library. Its simplicity and reliability make it perfect for retrieving data from the web

 in almost any format.</LibraryItem>
          <LibraryItem><strong>Spotipy:</strong> For managing playlists and interacting with Spotify’s Web API, Spotipy is essential. Whether it’s collecting data on songs or creating dynamic playlists, this library provides an easy way to automate Spotify-related tasks.</LibraryItem>
          <LibraryItem><strong>Praw:</strong> When collecting data from Reddit, Praw offers an easy-to-use API wrapper. It’s particularly useful for extracting posts, comments, and analyzing sentiment from Reddit’s massive content stream.</LibraryItem>
          <LibraryItem><strong>Public APIs GitHub Repo:</strong> I’ve been exploring free APIs from this GitHub repository, which provides a curated list of useful public APIs. It’s been invaluable in helping me discover new APIs for data collection and automation tasks. You can check it out here: <a href="https://github.com/public-apis/public-apis" target="_blank" rel="noopener noreferrer">Public APIs GitHub Repo</a>.</LibraryItem>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </LibrariesPageContainer>
  );
};

export default FavoriteDataScienceLibraries;