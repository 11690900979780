import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

// Define theme for consistent styling
const theme = {
  colors: {
    primary: '#0077b5',
    secondary: '#555',
    background: '#f5f5f5',
    sectionBackground: '#ffffff',
  },
  shadows: {
    light: '0 4px 8px rgba(0, 0, 0, 0.1)',
    medium: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  borderRadius: '12px',
  spacing: {
    small: '1rem',
    medium: '2rem',
    large: '3rem',
  },
};

// Styled components
const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing.large};
  background: linear-gradient(135deg, ${(props) => props.theme.colors.background} 0%, #e8e8e8 100%);
  min-height: 100vh;
  
  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.medium};
  }
`;

// Profile section with image and quote
const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing.large};
  max-width: 900px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProfileImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 6px solid ${(props) => props.theme.colors.primary};
  object-fit: cover;
  box-shadow: ${(props) => props.theme.shadows.medium};
  margin-right: ${(props) => props.theme.spacing.large};

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: ${(props) => props.theme.spacing.medium};
  }
`;

// Quote next to the image
const Quote = styled.blockquote`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  margin: 0;
  border-left: 6px solid ${(props) => props.theme.colors.primary};
  padding-left: ${(props) => props.theme.spacing.medium};
  
  @media (max-width: 768px) {
    text-align: center;
  }
`;

// Other sections as in your original code
const Section = styled.div`
  background-color: ${(props) => props.theme.colors.sectionBackground};
  box-shadow: ${(props) => props.theme.shadows.medium};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.spacing.medium};
  margin: ${(props) => props.theme.spacing.medium} 0;
  width: 100%;
  max-width: 900px;
  text-align: left;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.small};
    margin: ${(props) => props.theme.spacing.small} 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: ${(props) => props.theme.spacing.small};
  color: ${(props) => props.theme.colors.primary};
  border-left: 6px solid ${(props) => props.theme.colors.primary};
  padding-left: ${(props) => props.theme.spacing.small};
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: ${(props) => props.theme.colors.secondary};
  margin: 0;

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.2rem;
    text-decoration: underline; /* Underlines the h3 elements */
  }

  ul {
    margin-top: 1rem;
    padding-left: 40px;
    list-style-type: square;
    line-height: 1.6;
  }

  strong {
    color: #333;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;

    ul {
      padding-left: 20px;
    }
  }
`;

const DownloadButton = styled.a`
  margin-top: ${(props) => props.theme.spacing.medium};
  padding: 0.8rem 2rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s;
  box-shadow: ${(props) => props.theme.shadows.light};

  &:hover {
    background-color: #005f8d;
    box-shadow: ${(props) => props.theme.shadows.medium};
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
`;

const AboutMe = () => {
  return (
    <ThemeProvider theme={theme}>
      <AboutContainer>
        {/* Profile Image and Quote */}
        <ProfileSection>
          <ProfileImage src="/images/kp_photo.jpg" alt="Kyriakos Papadopoulos" />
          <Quote>
            Hi! I’m Kyriakos, a business administration student with a passion for data science and technology.
          </Quote>
        </ProfileSection>

        {/* Journey Section */}
        <Section>
          <SectionTitle>My Journey</SectionTitle>
          <SectionContent>
            <p>Technology has always captured my interest, but as I approached the end of high school, deciding on a career path felt overwhelming. While I had a strong interest in computer science, I opted to explore business management to potentially contribute to my family’s business. However, I soon recognized my desire to carve out a unique direction for myself.</p>
            <br />
            <p>Pursuing a degree in entrepreneurship ultimately brought me back to technology through a diploma in data analytics. This experience sharpened my focus on building a data-driven business career, further solidified through an intensive data science bootcamp that honed my technical skills.</p>
        </SectionContent>
        </Section>

        {/* Education Section */}
        <Section>
          <SectionTitle>Education</SectionTitle>
          <SectionContent>
            I am currently pursuing a <strong>BSc in Business Administration with a major in Entrepreneurship</strong> at <a href="https://acg.edu" target="_blank" rel="noopener noreferrer">The American College of Greece</a>. My education has covered a wide range of subjects:
            <ul>
              <li>Core courses such as <strong>Marketing, Economics, Accounting, Business Law, and Organizational Management</strong>, providing me with a comprehensive understanding of business operations.</li>
              <li>Specialized courses like <strong>Business-Driven Technology</strong> and <strong>New Venture Creation</strong>, which honed my skills in integrating technology and entrepreneurship.</li>
            </ul>
            <br />
            In addition, I completed two intensive courses with <a href="https://bigblue.academy/en" target="_blank" rel="noopener noreferrer">Big Blue Data Academy</a>:
            <ul>
              <li><strong>Data Analytics Diploma:</strong> Focused on Python, SQL, business intelligence, and descriptive analytics.</li>
              <li><strong>Data Science Bootcamp:</strong> Specialized in machine learning, deep learning, and big data technologies.</li>
            </ul>
          </SectionContent>
        </Section>

        {/* Professional Experience Section */}
        <Section>
          <SectionTitle>Professional Experience</SectionTitle>
          <SectionContent>
            <p>Throughout my career, I have gained diverse experiences:</p>
            <h3><a href="https://www.mentionlytics.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Mentionlytics</a></h3>
            <strong>Data Scientist</strong>
            <ul>
              <li> This experience was part of a short-term consulting project in collaboration with Big Blue Data Academy.</li>
              <li>Analyzed social media posts related to Greek tourism destinations using NLP techniques, providing key insights for industry stakeholders.</li>
            </ul>
            <h3><a href="https://epbooks.gr/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Papadopoulos Publishing SA</a></h3>
            <strong>Business Intelligence Analyst</strong>
            <ul>
              <li>Maintain existing BI reports.</li> 
              <li>Conduct ad hoc analyses to support business needs.</li> 
            </ul> 
            <br />
         
            <strong>E-commerce Coordinator</strong>
            <ul>
              <li>Optimized online sales through in-depth research and exploratory data analysis on ecommerce data.</li> 
              <li>Led the design and execution of marketing campaigns based on insights gathered.</li> 
              <li>Provided technical support to the marketing department and collaborated with external partners to enhance website functionality and resolve issues efficiently.</li> 
            </ul>       
            <br />
            
            <strong>Logistics Intern</strong>
            <ul>
              <li>Assisted in restructuring logistics processes and integrating ERP systems, enhancing operational efficiency.</li>
            </ul>
            <h3><a href="https://en.sev.org.gr/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Hellenic Federation of Enterprises</a></h3>
            <strong>Research Intern</strong>
            <ul>
              <li>This experience was part of a short term summer internship.</li>
              <li>Analyzed the impact of technology on future job requirements.</li>
              <li>Identified the key skills for organizational success.</li>
            </ul>
          </SectionContent>
        </Section>

        {/* Skills Section */}
        <Section>
          <SectionTitle>Skills</SectionTitle>
          <SectionContent>
            <ul>
              <li><strong>Data Analysis & Business Intelligence</strong>: Proficient in analyzing and interpreting complex datasets using tools like Excel, SQL, and Python (Pandas, NumPy). Experience in developing and maintaining BI reports and dashboards, providing actionable insights to support business decisions.</li>
              <br />  

              <li><strong>Data Science & Machine Learning</strong>: Skilled in conducting Exploratory Data Analysis (EDA), data cleaning, and preprocessing using Python. Experience in implementing machine learning models, including regression, classification, and clustering techniques to derive insights from data. Capable of performing NLP tasks like sentiment analysis, topic modeling, and named entity recognition (NER) using spaCy and Gensim.</li>
              <br />  

              <li><strong>Programming & Technology</strong>: Strong foundation in Python, with hands-on experience in libraries and frameworks such as Scikit-learn for machine learning and Keras for deep learning. Knowledge of web development using React.js, gained from building an online portfolio and projects related to web applications.</li>
              <br />  

              <li><strong>Business & Entrepreneurship</strong>: Comprehensive understanding of business functions such as marketing, accounting, organizational management, and business law. Experience in developing and executing business strategies, especially in e-commerce and marketing campaigns.</li>
              <br />  

              <li><strong>Project Management & Collaboration</strong>: Proven ability to manage projects efficiently, both independently and as part of a team. Strong communication and collaboration skills, enabling effective teamwork with colleagues, external partners, and clients.</li>
              <br />  

              <li><strong>Research & Analytical Skills</strong>: Skilled in conducting qualitative and quantitative research. Capable of translating research findings into practical business insights and identifying emerging trends in technology and markets.</li>
            </ul>
          </SectionContent>
        </Section>

        {/* Hobbies & Personal Interests Section */}
        <Section>
          <SectionTitle>Personal Interests</SectionTitle>
          <SectionContent>
            Beyond work, I am passionate about:
            <ul>
              <li><strong>Fitness</strong>: I enjoy weightlifting, running, cycling, and bouldering—activities that keep me physically active and mentally sharp, promoting discipline and resilience.</li>
              <br />  
              
              <li><strong>Arts</strong>: I have a deep appreciation for films, music, and non-fiction books. I enjoy exploring diverse narratives, genres, and themes, which broaden my perspective and inspire creativity.</li>
              <br />  

              <li><strong>Travel & Exploration</strong>: I love discovering new cultures and environments, enriching my worldview and sparking inspiration for my work and projects.</li>
            </ul>
          </SectionContent>
        </Section>

        {/* Download Resume Button */}
        <DownloadButton href="/files/cv.pdf" download="kyriakos_papadopoulos_cv.pdf">
          Download My Resume
        </DownloadButton>
      </AboutContainer>
    </ThemeProvider>
  );
};

export default AboutMe;