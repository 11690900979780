import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #b8860b; /* Gold color to match the title on the cover */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #555555; /* Dark gray for the author name */
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555555; /* Dark gray for the date */
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #f9f9f9; /* Light gray background for content */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #b8860b; /* Blue color to highlight sections */
  border-bottom: 2px solid #b8860b;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333333; /* Dark text for readability */
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #e4f6ea; /* Light blue background for quotes */
  border-left: 4px solid #b8860b;
  border-radius: 4px;
  color: #333333; /* Dark gray for quote text */
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333333;
`;

const OrderedList = styled.ol`
  padding-left: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #b8860b; /* Blue for tags */
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #b8860b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #1a8f63;
  }
`;

export const ChipWarMetadata = {
  title: "Chip War",
  description: "The Fight for the World's Most Critical Technology",
  tags: [
    'Technology', 'Semiconductors', 'Politics', 'Innovation', 'Supply Chain',
    'Geopolitics', 'Business', 'Trade', 'Competition', 'Market', 'Economy'
  ],
  author: "Chris Miller",
  datePublished: "2023-09-30",
  category: "Book Review",
  path: "/book-review/chip-war",
  coverImage: "/images/chip_war.jpg", // Ensure this path is correct
};

const ChipWar = () => {
  const metadata = ChipWarMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Chip War" delves into the global competition surrounding semiconductors, the technology powering
            the modern world. Chris Miller takes readers on a journey through the history, development, and strategic
            importance of microchips, highlighting their impact on the global economy and geopolitics.
          </p>
          <br />
          <p>
            The book examines how nations, especially the United States and China, have fought to dominate
            semiconductor manufacturing and innovation. Miller explores the critical role these technologies play in
            everything from smartphones to military equipment and emphasizes the challenges in securing supply chains.
          </p>
          <br />
          <p>
            "Chip War" not only offers an in-depth analysis of the industry but also discusses the consequences
            of technological dominance and how it shapes the geopolitical landscape. With engaging narratives and insights,
            Miller provides a clear perspective on the stakes of the chip race.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Semiconductor Dominance:</strong> The competition between nations for control over chip technology and production.</ListItem>
            <ListItem><strong>Geopolitical Impact:</strong> The strategic importance of microchips in global politics and military power.</ListItem>
            <ListItem><strong>Supply Chain Vulnerabilities:</strong> The challenges of securing supply chains in a globally interconnected market.</ListItem>
            <ListItem><strong>Innovation and Market Forces:</strong> How companies drive innovation in a highly competitive sector.</ListItem>
            <ListItem><strong>US-China Rivalry:</strong> The growing tensions between the US and China over technological supremacy.</ListItem>
            <ListItem><strong>Future of Technology:</strong> The potential paths and developments in the semiconductor industry and their implications for the future.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "The future of technology will be defined by those who control the chips."
          </Quote>
          <Quote>
            "In the battle for technological dominance, microchips have become the new oil."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "Chip War" opened my eyes to just how critical semiconductors are in shaping the modern world, not only from a technological perspective but also in terms of global politics and power dynamics. Before reading this, I had an understanding of the importance of microchips in everyday devices like phones and computers, but I hadn’t fully grasped their pivotal role in geopolitics.
          </p>
          <br />
          <p>
            One of the key takeaways for me was the fragile nature of the global supply chain. The book highlights how countries like the United States and China are fighting for dominance, not just in production but also in controlling the innovation behind semiconductors. The fact that this competition extends beyond economics into military applications really emphasizes how intertwined technology and national security have become.
          </p>
          <br />
          <p>
            What resonated most was the comparison of microchips to oil—a resource that nations will continue to fight over because it drives so much of modern life. It made me rethink the future of technology and how much innovation depends on access to these critical resources. It’s clear that whoever controls the future of semiconductors controls much more than just the tech industry; they control the future of global power itself.
          </p>
          <br />
          <p>
            This book left me with a deeper appreciation for the complexity of global competition and innovation. As someone who is interested in the intersection of technology and business, "Chip War" solidified my understanding of why advancements in technology aren’t just about convenience or progress—they’re about maintaining competitive advantage on a global scale.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default ChipWar;