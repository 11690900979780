import React from 'react';
import styled from 'styled-components';

const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #a6d6ea; /* Light blue background to match the cover */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #e07100; /* Orange color to match the title on the cover */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const Author = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
  font-style: italic;
`;

const CoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const DatePublished = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #e07100; /* Orange color */
  border-bottom: 2px solid #f57c00;
  padding-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
`;

const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #ffe0c0;
  border-left: 4px solid #e07100;
  border-radius: 4px;
  color: #555;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

const Tag = styled.span`
  background-color: #e07100;
  color: white;
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #e07100;
    color: #000;
  }
`;

export const PrisonersOfGeographyMetadata = {
  title: "Prisoners of Geography",
  description: "Ten Maps That Tell You Everything You Need to Know About Global Politics",
  tags: [
    'Geopolitics', 'Natural Resources', 'Economic Development', 'Globalization', 'Climate Change',
    'Cultural Identity', 'International Relations'
  ],
  author: "Tim Marshall",
  datePublished: "2024-08-25",
  category: "Book Review",
  path: "/book-review/prisoners-of-geography",
  coverImage: "/images/prisoners_of_geography.jpg", // Ensure this path is correct
};

const PrisonersOfGeography = () => {
  const metadata = PrisonersOfGeographyMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
          {metadata.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
      </TagContainer>
      <br />
      <br />
      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            The book explores how geography shapes the destiny of nations and influences global politics.
            The central thesis is that the physical characteristics of countries—mountains, rivers, deserts,
            and seas—have historically constrained the choices available to political leaders and continue to
            do so in the modern world.
          </p>
          <br />
          <p>
            Marshall divides the book into ten chapters, each focusing on a different region or country, including
            Russia, China, the United States, Western Europe, Africa, the Middle East, India and Pakistan, Korea and Japan,
            Latin America, and the Arctic. In each chapter, he examines how geographic factors such as natural barriers,
            access to resources, and climate impact political decisions, economic development, and international relations.
          </p>
          <br />
          <p>
            For example, in the chapter on Russia, Marshall discusses how the vast, flat plains of the country have historically
            left it vulnerable to invasion, shaping its expansionist policies and its desire for buffer zones. In the chapter on China,
            he explores how the country’s natural barriers have both protected it and limited its expansion, while also shaping its
            current geopolitical strategies, such as the Belt and Road Initiative.
          </p>
          <br />
          <p>
            Marshall’s analysis highlights the enduring power of geography in a world that is increasingly interconnected. While technology
            and globalization have changed many aspects of international relations, he argues that the fundamental influence of geography
            remains as strong as ever. The book is accessible, well-researched, and provides readers with a nuanced understanding of why
            countries act the way they do on the global stage.
          </p>
        </SectionContent>
      </ContentSection>
      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Geography as Destiny:</strong> Geography plays a crucial role in shaping the political and economic decisions of nations, influencing their histories, cultures, and foreign policies.</ListItem>
            <ListItem><strong>Geopolitics and Power:</strong> Geographic features can create strategic vulnerabilities for nations, leading them to adopt defensive or aggressive strategies.</ListItem>
            <ListItem><strong>Natural Barriers and Borders:</strong> The availability or scarcity of natural resources significantly impacts a country’s wealth, power, and international relations.</ListItem>
            <ListItem><strong>Resource Distribution and Economic Development:</strong> Geographical disputes over borders, territories, and resources often root global conflicts.</ListItem>
            <ListItem><strong>Security and Vulnerability:</strong> Despite globalization, geographic constraints still limit the extent to which nations can transcend their physical environments.</ListItem>
            <ListItem><strong>Historical Influence of Geography:</strong> Geography shapes the culture and identity of nations, influencing their historical trajectories and cultural development.</ListItem>
            <ListItem><strong>Regional Conflicts and Tensions:</strong> Geography and climate influence human activity, settlement patterns, and migration, affecting regional conflicts and tensions.</ListItem>
            <ListItem><strong>Technological Impact vs. Geographic Constraints:</strong> Technology changes aspects of international relations, but geography’s influence remains significant.</ListItem>
            <ListItem><strong>Globalization and Geography:</strong> Despite global interconnectedness, geography continues to dictate international strategies and relations.</ListItem>
            <ListItem><strong>Climate Change and Future Geopolitics:</strong> Climate change poses new geopolitical challenges, impacting migration, conflict, and resource competition.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>
      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
           Why do you think your values would work in a culture you don’t understand?
          </Quote>
          <Quote>
            The final frontier has always called out to our imagination, but ours is the age in which humanity has lived the dream and pushed out into space, a millimeter into infinity, on our way to the future.
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "Prisoners of Geography" reminded me of the subtle yet undeniable influence of geography on world events. It gave me a fresh perspective
            on how much of what happens globally—conflicts, alliances, and even economic policies—are influenced by factors like mountain ranges and rivers.
          </p>
          <br />
          <p>
            One of the most striking takeaways for me was the constant struggle of nations like Russia and China to protect their vulnerabilities due to 
            geography. It’s fascinating to see how these countries' geopolitical strategies are largely shaped by physical landscapes that date back centuries.
            This realization has changed the way I view news related to geopolitics, as I now understand there are historical and geographical reasons behind
            many international decisions.
          </p>
          <br />
          <p>
            What stood out to me most was the timeless nature of geography’s influence—it’s an unchangeable force that continues to shape the present
            and the future, even as the world becomes more interconnected through technology. It’s a humbling reminder that while humans can innovate,
            we can never escape the physical limits set by the earth.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default PrisonersOfGeography;