import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { DiPython, DiJavascript1, DiReact } from 'react-icons/di';
import { sentimentAnalysisMetadata } from './projects/MentionlyticsSentiment';
import { carpoolingAppMetadata } from './projects/CarpoolingApp';
import { spotifyAPIMetadata } from './projects/SpotifyAPIProject';
import { BbdaClusteringMetadata } from './projects/BbdaClustering';
import { BbdaInteractiveDashboardMetadata } from './projects/BbdaInteractiveDashboard';
import { SpotifyPlaylistCreatorMetadata } from './projects/SpotifyPlaylistCreatorProject';
import { BbdaRegressionMetadata } from './projects/BbdaRegression';

// Container for projects
const ProjectsContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f5f7;
  min-height: 100vh;
`;

// Grid for project cards
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
`;

// Styled project card with hover effect
const ProjectCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: none;
  position: relative; /* For positioning the badge */

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
`;

// WIP Badge (Image-based)
const WIPBadge = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 70px;
  height: auto;
`;

// Project image styling
const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

// Project content
const ProjectContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

// Project title with hover effect
const ProjectTitle = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  color: #2a2a2a;
  font-weight: bold;
  transition: color 0.3s;

  ${ProjectCard}:hover & {
    color: #0077b5; /* Change color on hover */
  }
`;

// Project description
const ProjectDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
  flex-grow: 1;
`;

// Date published
const DatePublished = styled.p`
  font-size: 0.9rem;
  color: #888;
  margin-top: auto;
  text-align: right;
  font-style: italic;
`;

// Filter container
const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

// Dropdown button and menu for filtering
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .dropdown-menu {
    display: block;
  }

  &:hover .dropdown-button {
    background-color: #6c757d;
  }
`;

const DropdownButton = styled.div`
  background-color: #adb5bd;
  color: white;
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  font-size: 1rem;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 10;
  display: none; /* Hidden by default */
  width: 320px;
`;

const DropdownSearch = styled.input`
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

const DropdownItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

// Projects component
const Projects = () => {
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Collect all project metadata in one array
  const projectsData = [
    sentimentAnalysisMetadata,
    carpoolingAppMetadata,
    spotifyAPIMetadata,
    BbdaClusteringMetadata,
    BbdaInteractiveDashboardMetadata,
    SpotifyPlaylistCreatorMetadata,
    BbdaRegressionMetadata,
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  const handleTagSelect = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage((prev) => (prev === language ? '' : language));
  };

  const handleClearFilters = () => {
    setSelectedTags([]);
    setSelectedLanguage('');
  };

  // Filter and sort projects based on selected tags and date
  const filteredProjects = projectsData
    .filter((project) =>
      selectedTags.length === 0
        ? true
        : selectedTags.every((tag) => project.tags.includes(tag))
    )
    .filter((project) =>
      selectedLanguage === ''
        ? true
        : project.languages.includes(selectedLanguage)
    )
    .sort((a, b) => new Date(b.datePublished) - new Date(a.datePublished));

  // Get all unique tags from the filtered projects
  const filteredTagsList = Array.from(
    new Set(filteredProjects.flatMap((project) => project.tags))
  ).sort();

  // Get all unique languages from the filtered projects
  const filteredLanguagesList = Array.from(
    new Set(filteredProjects.flatMap((project) => project.languages))
  );

  const filteredTags = filteredTagsList.filter((tag) =>
    tag.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <ProjectsContainer>
      <h1>Projects</h1>
      <br></br>
      <FilterContainer>
        <DropdownContainer>
          <DropdownButton className="dropdown-button">
            Filter by Tags <FiChevronDown />
          </DropdownButton>
          <DropdownMenu className="dropdown-menu">
            <DropdownSearch
              type="text"
              placeholder="Search tags..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {filteredTags.map((tag) => (
              <DropdownItem key={tag} onClick={() => handleTagSelect(tag)}>
                {tag}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownContainer>
  
        {/* Wrapper for Title and Icons */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <h3 style={{ margin: 0, color: '#495057' }}>Filter by Technology: </h3>
          <div style={{ display: 'flex', gap: '1.5rem' }}>
            {filteredLanguagesList.includes('Python') && (
              <div
                style={{
                  cursor: 'pointer',
                  fontSize: '2rem',
                  color: selectedLanguage === 'Python' ? '#0077b5' : '#6c757d',
                }}
                onClick={() => handleLanguageSelect('Python')}
              >
                <DiPython />
              </div>
            )}
            {filteredLanguagesList.includes('JavaScript') && (
              <div
                style={{
                  cursor: 'pointer',
                  fontSize: '2rem',
                  color:
                    selectedLanguage === 'JavaScript' ? '#0077b5' : '#6c757d',
                }}
                onClick={() => handleLanguageSelect('JavaScript')}
              >
                <DiJavascript1 />
              </div>
            )}
            {filteredLanguagesList.includes('React') && (
              <div
                style={{
                  cursor: 'pointer',
                  fontSize: '2rem',
                  color: selectedLanguage === 'React' ? '#0077b5' : '#6c757d',
                }}
                onClick={() => handleLanguageSelect('React')}
              >
                <DiReact />
              </div>
            )}
            {filteredLanguagesList.includes('PowerBI') && (
              <div
                style={{
                  cursor: 'pointer',
                  fontSize: '2rem',
                  color: selectedLanguage === 'PowerBI' ? '#0077b5' : '#6c757d',
                }}
                onClick={() => handleLanguageSelect('PowerBI')}
              >
                <img
                  src="/images/powerbi.png"
                  alt="Power BI"
                  style={{
                    width: '2rem',
                    filter:
                      selectedLanguage === 'PowerBI'
                        ? 'none'
                        : 'grayscale(100%)',
                    transition: 'filter 0.2s',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </FilterContainer>
  
      {(selectedTags.length > 0 || selectedLanguage) && (
        <button
          style={{
            backgroundColor: '#ff6b6b',
            color: 'white',
            border: 'none',
            padding: '0.5rem 1rem',
            borderRadius: '20px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            marginTop: '1rem',
          }}
          onClick={handleClearFilters}
        >
          Clear Filters
        </button>
      )}
      <GridContainer>
        {filteredProjects.map((project, index) => (
          <ProjectCard key={index} onClick={() => handleCardClick(project.path)}>
            <ProjectImage src={project.coverImage} alt={project.title} />
            {project.status === 'WIP' && (
              <WIPBadge src="/images/wip.png" alt="WIP Badge" />
            )}
            {project.status === 'Halted' && (
              <WIPBadge src="/images/halted.png" alt="Halted Badge" />
            )}
            <ProjectContent>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <DatePublished>
                <strong>Published:</strong> {project.datePublished}
              </DatePublished>
            </ProjectContent>
          </ProjectCard>
        ))}
      </GridContainer>
    </ProjectsContainer>
  );
};

export default Projects;