import React from 'react';
import styled from 'styled-components';

const ProjectDetailContainer = styled.div`
  padding: 3rem 1.5rem;
  background-color: #f2f7ff; /* Light background for readability */
  min-height: 100vh;
  max-width: 1100px;
  margin: 0 auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  border-radius: 12px;
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 2.5rem;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: #1c1c1c; /* Darker shade for titles */
  margin-bottom: 0.5rem;
`;

const Date = styled.p`
  font-size: 1rem;
  color: #888; /* Neutral grey for dates */
`;

const TagsContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center; /* Align tags left for readability */
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: #0073e6; /* Blue for a more modern look */
  color: white;
  padding: 0.3rem 0.8rem;
  border-radius: 16px;
  font-size: 0.85rem;
`;

const Image = styled.img`
  display: block;
  max-width: 80%;
  height: auto;
  margin: 0 auto 2rem auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow */
`;

const Section = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.2rem;
  border-bottom: 3px solid #0073e6;
  padding-bottom: 0.3rem;
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444; /* Softer color for better readability */
  margin-bottom: 1.5rem;
`;

const List = styled.ul`
  padding-left: 1.5rem;
  list-style-type: disc;
  color: #444;
`;

const ListItem = styled.li`
  margin-bottom: 0.8rem; /* Add more spacing between bullet points */
  line-height: 1.6;
`;

const Button = styled.button`
  display: flex;
  margin: 0 auto;
  padding: 0.9rem 2.2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0073e6; /* Blue for buttons */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  
  &:hover {
    background-color: #005bb5; /* Darker blue for hover */
  }
`;

const BbdaInteractiveDashboard = () => {
  const tags = ['Python', 'Power BI', 'Plotly', 'Dash', 'Health', 'Data Visualization'];
  const datePublished = '2024-05-25';

  return (
    <ProjectDetailContainer>
      <Header>
        <Title>Lung Cancer Mortality Dashboard</Title>
        <Date>Published on: {datePublished}</Date>
      </Header>

      {/* Displaying images of the dashboard */}
      <Image src="/images/bbda_bi.png" alt="Demographic Dashboard" />

      {/* Tags */}
      <TagsContainer>
        {tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagsContainer>

      <br />
      <br />
      <br />

      {/* Collaborators */}
      <Section>
        <SectionTitle>Collaborators</SectionTitle>
        <List>
        <ListItem>
            <a href="https://www.linkedin.com/in/aliki-koutsodonti-906390166/" target="_blank" rel="noopener noreferrer">
             Aliki Koutsodonti
            </a>
          </ListItem>
          <ListItem>
            <a href="https://www.linkedin.com/in/konstantinos-lamprou-943038213/" target="_blank" rel="noopener noreferrer">
             Konstantinos Lamprou
            </a>
            </ListItem>
        </List>
      </Section>

      {/* Overview */}
      <Section>
        <SectionTitle>Overview</SectionTitle>
        <Text>
        This project was conducted as part of the Data Science Bootcamp from Big Blue Data Academy. 
        It was the second practice project of the course,  and was completed in around 4 hours on the 1st of June 2024. It uses
        tools such as Dash, Plotly, and Power BI to create interactive dashboards. The aim was to present critical lung
        cancer statistics across various countries, including smoking analysis, treatment outcomes, and trends within
        Greece. These dashboards provide interactive insights into the dataset, allowing users to filter data
        dynamically and visualize relationships between different factors.
        </Text>
      </Section>

      {/* Objectives */}
      <Section>
        <SectionTitle>Objectives</SectionTitle>
        <Text>The primary objectives of this project were:</Text>
        <List>
          <ListItem>To explore key demographics and health factors in lung cancer mortality rates.</ListItem>
          <ListItem>To present detailed analyses of the impact of smoking on cancer incidence.</ListItem>
          <ListItem>To analyze the relationship between treatment types and patient outcomes.</ListItem>
          <ListItem>To provide specific insights into lung cancer cases within Greece.</ListItem>
        </List>
      </Section>

      {/* Tools Used */}
      <Section>
        <SectionTitle>Tools Used</SectionTitle>
        <Text>The following tools and libraries were utilized in this project:</Text>
        <List>
          <ListItem><strong>Python</strong></ListItem>
          <ListItem><strong>Pandas</strong> and <strong>NumPy</strong> for data manipulation</ListItem>
          <ListItem><strong>Plotly</strong> and <strong>Dash</strong> for interactive dashboards</ListItem>
          <ListItem><strong>Power BI</strong> for additional visualizations</ListItem>
        </List>
      </Section>

      {/* Methodology */}
      <Section>
        <SectionTitle>Methodology</SectionTitle>
        <Text>
          The dataset includes demographic data, smoking status, treatment types, and country-specific information. The
          interactive dashboard uses dynamic filtering and plotting to allow users to adjust variables such as age,
          cancer stage, and family history. Additionally, the dashboards display insights about smokers and their impact
          on cancer incidences, treatment outcomes, and patient survival rates.
        </Text>
      </Section>

      {/* Results */}
      <Section>
        <SectionTitle>Results</SectionTitle>
        <Text>
          This project provided interactive insights into the lung cancer dataset, revealing significant patterns:
        </Text>
        <List>
          <ListItem>
            <strong>Demographic Dashboard:</strong>
            <Text>Insights into the incidence of lung cancer across various countries, focusing on age and gender distributions.</Text>
            <img src="/images/projects/bbda_bi_1.png" alt="Demographic Dashboard" style={{ maxWidth: '100%', height: 'auto' }}/>
          </ListItem>
          <ListItem>
            <strong>Smoker Analysis:</strong>
            <Text>Showed the impact of smoking status on cancer rates, with filters for diagnosis year and age group.</Text>
            <img src="/images/projects/bbda_bi_2.png" alt="Smoker Analysis" style={{ maxWidth: '100%', height: 'auto' }}/>
          </ListItem>
          <ListItem>
            <strong>Treatment Dashboard:</strong>
            <Text>Correlated survival rates with different treatment types and cancer stages.</Text>
            <img src="/images/projects/bbda_bi_3.png" alt="Treatment Dashboard" style={{ maxWidth: '100%', height: 'auto' }} />
          </ListItem>
          <ListItem>
            <strong>In Depth Country Analysis:</strong>
            <Text>This page provides an in depth view of incidents for the country selected.</Text>
            <img src="/images/projects/bbda_bi_4.png" alt="Greece Dashboard" style={{ maxWidth: '100%', height: 'auto' }}/>
          </ListItem>
          {/* Embedding the Jupyter notebook as an HTML file */}
          <ListItem>
           <strong>Interactive Dashboard with Plotly and Dash:</strong>
           <Text>This dashboad explores the correlations between risk factors and lung cancer treatment outcomes.</Text>
           <iframe 
              src="/embeds/dash_lung_cancer_correlation.html" 
              width="100%" 
              height="800" 
              frameBorder="0"
              title="Dash Notebook">
            </iframe>
    </ListItem>
        </List>
      </Section>

      {/* Conclusion */}
      <Section>
        <SectionTitle>Conclusion</SectionTitle>
        <Text>
          The project successfully created a set of interactive dashboards that provide an in-depth analysis of lung
          cancer mortality data. These dashboards allow users to explore relationships between smoking, treatment types,
          and survival rates in a visually intuitive way.
        </Text>
      </Section>

      <Button onClick={() => window.history.back()}>Back to Projects</Button>
    </ProjectDetailContainer>
  );
};

export const BbdaInteractiveDashboardMetadata = {
  title: 'Lung Cancer Mortality Interactive Dashboard',
  description:
    'Created interactive dashboards presenting lung cancer mortality data across demographics, smokers, treatments, and Greece-specific trends.',
  tags: ['Python', 'Power BI', 'Plotly', 'Dash', 'Health', 'Data Visualization'],
  languages: ['Python', 'PowerBI'],
  datePublished: '2024-05-25',
  path: '/projects/bbda-interactive-dashboard',
  coverImage: '/images/bbda_bi.png',
};

export default BbdaInteractiveDashboard;